import { COLORS } from 'constants/colors';

import { textTypeStyles } from 'mixins/TextTypeStyles';
import styled from 'styled-components';

import { TextTypes } from 'components/Text/Text.types';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledTilesPage = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const StyledTilesComponent = styled.div`
    margin: 124px 10px;
    max-width: 1110px;

    @media (max-width: 1000px) {
        margin: 32px 24px;
    }
`;

export const StyledTiles = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 12px;

    @media (max-width: 1000px) {
        margin-top: 16px;
    }
`;

export const StyledTile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 316px;
    height: auto;
    border-radius: 16px;
    padding: 16px;
    margin: 12px 0px;

    &:hover {
        background-color: ${hexToRgba(COLORS.white, 0.05)};
    }

    @media (max-width: 1000px) {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    @media (max-width: 1096px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const StyledTextBox = styled.div`
    padding: 8px;
    padding-right: 10px;

    @media (max-width: 1000px) {
        padding-right: 0;
    }
`;

export const StyledIcon = styled.div`
    min-width: 75px;
    width: 75px;
    min-height: 75px;
    height: 75px;
`;

export const StyledTitle = styled.div`
    ${textTypeStyles(TextTypes.BODY16)};
    color: ${COLORS.white};
    -webkit-text-size-adjust: 100%;
`;

export const StyledDescription = styled.div`
    ${textTypeStyles(TextTypes.BODY14)};
    color: ${hexToRgba(COLORS.white, 0.5)};
    min-height: 40px;
    -webkit-text-size-adjust: 100%;
`;
