import React from 'react';

export const TESTIMONIALS = [
    {
        title: 'Really good',
        description: 'Made my Mac look really nice, recommend this',
        starts: 5,
        nickname: 'alexhasbunda',
        country: 'United Kindom',
    },
    {
        title: 'Concise and convenient',
        description:
            'It is very convenient, there is not much content at present, but it is enough to be used, and the interface is simple. ',
        starts: 4,
        nickname: '🌙🎉',
        country: 'China',
    },
    {
        title: 'Great data on my laptop',
        description: 'Super app. I love the minimalistic look of the «Golden Age»',
        starts: 5,
        nickname: 'ejose',
        country: 'United States of America',
    },
    {
        title: 'Good',
        description: 'Nice widgets. Thank you.',
        starts: 4,
        nickname: 'ER Tobi',
        country: 'India',
    },
    {
        title: 'Very slick',
        description: 'Super bit of software and gives you some great options for customizing your machine.',
        starts: 4,
        nickname: 'CREZO',
        country: 'United Kindom',
    },
];

export const BOTTOM_TESTIMONIAL = [
    'We care about all our users: both those who are just getting acquainted with Widgetter and those who have been with us for a long time. Therefore, we regularly release updates with',
    <b key="0"> new themes, HD 4k wallpapers and widgets, </b>,
    'as well as take into account the experience of our users and the ideas and comments that we receive. Feel free to contact us!',
];

export const STARS_ARRAY = [0, 1, 2, 3, 4];

export const WHITESPACE = ' ';
