import { COLORS } from 'constants/colors';
import { DESCRIPTION, TITLE, WIDGETS } from 'constants/macBook';

import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { macBookIconPath, macBookWithWidgetsPath } from 'assets';
import { useWindowSizeContext } from 'context/WindowSizeContext';

import { Text } from 'components/Text';
import { WidgetWrapper } from 'components/WidgetWrapper';

import {
    StyledBlueCircle,
    StyledButton,
    StyledDescriptionText,
    StyledMac,
    StyledMacBook,
    StyledMacBookComponent,
    StyledMacBookWithWidgets,
    StyledOrangeCircle,
    StyledTextBox,
} from './MacBook.style';

export const MacBook = React.forwardRef<HTMLAnchorElement>((_, ref) => {
    const [elementHover, setElementHover] = useState(false);
    const { isMobileSize } = useWindowSizeContext();

    return (
        <StyledMacBookComponent>
            <StyledTextBox>
                <div>
                    <Text
                        fontWeight="700"
                        fontSize={isMobileSize ? '32px' : '42px'}
                        lineHeight={isMobileSize ? '36px' : '48px'}
                    >
                        {TITLE}
                    </Text>
                </div>
                <StyledDescriptionText fontWeight="400" fontSize="18px" lineHeight="24px">
                    {DESCRIPTION}
                </StyledDescriptionText>
            </StyledTextBox>
            <div
                onClick={() => {
                    ReactGA.event('download_widgetter', {
                        source: 'main'
                    });
                }}
            >
                <StyledButton
                    ref={ref}
                    width={169}
                    height={48}
                    backgroundColor={COLORS.blue}
                    backgroundColorHover={COLORS.blueHover}
                    label={'Download'}
                    borderRadius={12}
                    href={'https://widgetter.com/download'}
                />
            </div>
            <StyledMacBook>
                <StyledOrangeCircle />
                <StyledBlueCircle />
                {!isMobileSize ? (
                    <>
                        <StyledMac src={macBookIconPath} width={946} height={578} />
                        {WIDGETS.map(({ diraction, title, description, widget, top, right, left }, ind) => (
                            <WidgetWrapper
                                key={ind}
                                diraction={diraction}
                                title={title}
                                description={description}
                                widget={widget}
                                top={top}
                                right={right}
                                left={left}
                                elemHover={elementHover}
                                setElemHover={setElementHover}
                            />
                        ))}
                    </>
                ) : (
                    <StyledMacBookWithWidgets src={macBookWithWidgetsPath} />
                )}
            </StyledMacBook>
        </StyledMacBookComponent>
    );
});
