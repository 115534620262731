import { COLORS } from 'constants/colors';

import styled from 'styled-components';

import { Text } from 'components/Text';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledStoriesSwitcherComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: calc(100vw / 15 * 8);

    @media (min-width: 1440px) {
        height: 768px;
    }
    @media (max-width: 1440px) {
        height: 768px;
    }
    @media (max-width: 578px) {
        height: calc(100vw * 550 / 414);
    }
`;

export const StyledStoriesWrap = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1440px;
    height: calc(100vw / 15 * 8);

    @media (min-width: 1440px) {
        height: 768px;
    }
    @media (max-width: 1440px) {
        height: 768px;
    }
    @media (max-width: 578px) {
        height: calc(100vw * 550 / 414);
    }
`;

export const StyledBackground = styled.img<{ positionNumber: number; activeIndex: number }>`
    transform: translateZ(${({ activeIndex, positionNumber }) => (activeIndex === positionNumber ? '0' : '-1')});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.4s cubic-bezier(1, 1, 0, 0);
    opacity: ${({ activeIndex, positionNumber }) => (activeIndex === positionNumber ? '1' : '0')};
    object-fit: contain;
    max-width: 1440px;
    justify-self: center;

    @media (max-width: 1000px) {
        left: calc(50% - (768px * 414 / 550) / 2);
        height: 768px;
        width: auto;
    }
    @media (max-width: 578px) {
        left: calc(50% - 50vw);
        width: 100vw;
        height: calc(100vw * 550 / 414);
    }
`;

export const StyledHeaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-top: 40px;
    z-index: 1;

    @media (max-width: 1000px) {
        margin-top: 32px;
    }
`;

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    width: 100%;

    @media (max-width: 1000px) {
        width: calc(768px * 414 / 512 - 48px);
    }
    @media (max-width: 578px) {
        width: calc(100vw - 48px);
    }
`;

export const StyledLines = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    max-width: 1110px;
    width: 100%;

    @media (max-width: 1142px) {
        width: calc(100% - 32px);
    }
`;
export const StyledLine = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${hexToRgba(COLORS.white, 0.2)};
`;
export const StyledLineWrap = styled.div<{ positionNumber: number; count: number; isMobile: boolean }>`
    height: 2px;
    border-radius: 1px;
    width: 100%;
    margin: 0px 5px;
    ${({ count, positionNumber }) => positionNumber === count && 'margin-right: 0px'};
    ${({ positionNumber }) => positionNumber === 0 && 'margin-left: 0px'};
    padding: 7px 0px;
    ${({ isMobile }) => !isMobile && 'cursor:pointer'};
`;

export const StyledProgress = styled.div<{
    positionNumber: number;
    activeIndex: number;
    time: number;
}>`
    transition: width ${({ time }) => time}s cubic-bezier(1, 1, 0, 0);
    height: 100%;
    width: 0px;
    ${({ activeIndex, positionNumber }) => (positionNumber === activeIndex ? 'width:100%' : 'transition: none')};
    background-color: ${COLORS.white};
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1110px;

    @media (max-width: 1142px) {
        width: calc(100% - 32px);
    }
`;

export const StyledTitle = styled(Text)`
    margin-top: 17px;
    margin-bottom: 8px;
`;

export const StyledTouchScreen = styled.div<{ rightSide: boolean }>`
    position: absolute;
    top: 0;
    ${({ rightSide }) =>
        rightSide ? 'right:calc(50% - (768px * 414 / 550) / 2)' : 'left:calc(50% - (768px * 414 / 550) / 2)'};
    height: 100%;
    width: 30%;
    background: transparent;
    cursor: pointer;

    @media (max-width: 578px) {
        ${({ rightSide }) => (rightSide ? 'right:0' : 'left:0')};
    }
`;
