import Big_01 from './big/01.jpg';
import Big_02 from './big/02.jpg';
import Big_03 from './big/03.jpg';
import Big_04 from './big/04.jpg';
import Big_05 from './big/05.jpg';
import Big_06 from './big/06.jpg';
import Big_07 from './big/07.jpg';
import Big_08 from './big/08.jpg';
import Big_09 from './big/09.jpg';
import Big_10 from './big/10.jpg';
import Big_11 from './big/11.jpg';
import Big_12 from './big/12.jpg';
import Small_01 from './small/01.jpg';
import Small_02 from './small/02.jpg';
import Small_03 from './small/03.jpg';
import Small_04 from './small/04.jpg';
import Small_05 from './small/05.jpg';
import Small_06 from './small/06.jpg';
import Small_07 from './small/07.jpg';
import Small_08 from './small/08.jpg';
import Small_09 from './small/09.jpg';
import Small_10 from './small/10.jpg';
import Small_11 from './small/11.jpg';
import Small_12 from './small/12.jpg';

export const BACKGROUNDS = [
    [Big_01, Small_01],
    [Big_02, Small_02],
    [Big_03, Small_03],
    [Big_04, Small_04],
    [Big_05, Small_05],
    [Big_06, Small_06],
    [Big_07, Small_07],
    [Big_08, Small_08],
    [Big_09, Small_09],
    [Big_10, Small_10],
    [Big_11, Small_11],
    [Big_12, Small_12],
];
