import React from 'react';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';

import { PRIVACY } from './RefundPrivacyPage.constants';
import {
    StyledParagraphs,
    StyledPrivacy,
    StyledPrivacyPage,
    StyledSubTitle,
    StyledTitle,
} from './RefundPrivacyPage.style';

export const RefundPrivacyPage: React.FC = () => {
    return (
        <StyledPrivacyPage>
            <StyledPrivacy>
                <StyledTitle>
                    <div>
                        <Text fontWeight="700" fontSize="32px" lineHeight="38px">
                            Refund Policy
                        </Text>
                    </div>
                    <div>
                        <Text type={TextTypes.BODY16} lineHeight="1.75">
                            If for any reason the User is not satisfied with the purchase of access to the Application,
                            the User should read the return policy and the license agreement.
                        </Text>
                    </div>
                </StyledTitle>
                {PRIVACY.map((item) => (
                    <>
                        <StyledSubTitle>
                            <Text type={TextTypes.TITLE16}>{item.title}</Text>
                        </StyledSubTitle>

                        {item.paragraphs.map((paragraph, pInd) => (
                            <StyledParagraphs key={pInd} numberOfLine={pInd} countOfLines={item.paragraphs.length}>
                                <Text type={TextTypes.BODY16} lineHeight="1.75">
                                    {paragraph}
                                </Text>
                            </StyledParagraphs>
                        ))}
                    </>
                ))}
            </StyledPrivacy>
        </StyledPrivacyPage>
    );
};