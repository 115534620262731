import React from 'react';

import { Text } from 'components/Text';

import { StyledHref, StyledTable, StyledTH, StyledUnorderedList } from './TermsPage.style';

export const TERMS = [
    {
        paragraphs: ['Bitnite, LLP is the right holder of the Widgetter software (application).'],
    },
    {
        paragraphs: [
            'The subject of this license agreement is the granting of a non-exclusive right to use the Widgetter to you (the user), also the rights and obligations of the Right holder and the User.',
        ],
    },
    {
        paragraphs: [
            'The Right holder asks Users read this license agreement carefully before downloading and installing the Application on the computer. By downloading, installing and (or) using the Widgetter, the User agrees to comply with the terms of the License Agreement, be abide by its provisions when using the Widgetter.',
        ],
    },
    {
        paragraphs: [
            [
                'Also, the granting of a non-exclusive right to use depends on the User’s consent and compliance with the provisions of Privacy Policy of the Right Holder, the Refund Policy. Before using the Application, the User must read the Privacy Policy, located at: ',
                <StyledHref key={1} href="https://widgetter.com/privacy" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/privacy
                </StyledHref>,
                ', the Refund Policy ',
                <StyledHref key={3} href="https://widgetter.com/refund-policy" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/refund-policy
                </StyledHref>,
            ],
        ],
    },
    {
        paragraphs: [
            'If the User does not agree with the provisions of the License Agreement, Privacy Policy, Refund Policy, the User must stop using the Widgetter and remove it from his Device.',
        ],
    },

    {
        title: 'Article 1. Terms and definitions',
        paragraphs: [
            '1. Widgetter / application - software (application) that allows the User to optimize the working space on the computer, including optimization of controls, collection of basic information about the performance of the computer.',
            'The functionality of the Application is set out on the Website, as well as in the Application itself.',
            'The Application includes:',
            <StyledUnorderedList key={3}>
                <li>registration information;</li>
                <li>documentation (installation instructions, etc.);</li>
                <li>setup files.</li>
            </StyledUnorderedList>,
            'The Application includes all updates, modifications provided by the Right holder to the User.',
            [
                '2. Website – website ',
                <StyledHref key={1} href="https://widgetter.com/" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/
                </StyledHref>,
                'from which the User can download the Application. The Site also includes all subdomains of the Site.',
            ],
            '3. Subscription - the number of rights granted to the User for a fee or free of charge, depending on the functionality of the Application provided to the User.',
            '4. Device – Mac (any model) legally owned by the User.',
            '5. User – an individual using the Application.',
            '6. The Payment Provider – the Legal Entity Paddle LLC, authorized by the Right Holder to accept payments from the User.',
        ],
    },

    {
        title: 'Article 2. General conditions for the application of the License Agreement',
        paragraphs: [
            <i key={0}>Changes to the License Agreement</i>,
            '1. The Right Holder has the right to make changes to the License Agreement at any time, also in case of changing the functionality of the Application.',
            '2. The new version of the License Agreement has the legal force from the date the new version of the License Agreement is published on the Site.',
            '3. The version posted on the Site is up-to-date.',
            '4. The Right Holder notifies the User of changes to the License Agreement at own discretion.',
            '5. The Right Holder strongly recommends the User to re-read the License Agreement before each launch of the Application.',
            '6. If the User uses the Application after making changes to the License Agreement, this means his acceptance of the new version of the License Agreement.',
            '7. If the User does not agree with the new version of the License Agreement, the User must stop using the Application and delete it from the Device. In this case, the License Agreement shall terminate in accordance with Article 10 of the License Agreement.',
            <i key={8}>Beginning of the License Agreement</i>,
            '8. The License Agreement for a free subscription is valid from the moment the User starts using the Application.',
            '9. The License Agreement for a paid subscription is valid from the moment the subscription is paid.',
        ],
    },

    {
        title: 'Article 3. Protection of the Rights of the Right Holder. Permitted Use and Restrictions on the Application',
        paragraphs: [
            <i key={0}>Protection of the Right Holder’s rights </i>,
            '1. The Application is the object of copyright of the Right Holder and is protected by laws on the protection of intellectual property, international treaties on intellectual property.',
            '2. The Application is protected by copyright, trademarks and other laws of the republic Kazakhstan and foreign countries.',
            <i key={3}>Permitted Uses and Restrictions on the Application</i>,
            '3. The Right Holder grants the User a non-exclusive, limited, revocable, non-transferable license to use the Application subject to the following features (permitted use):',
            <StyledUnorderedList key={5}>
                <li>
                    The User can use the Application provided under a paid subscription (clauses 4-12 of Article 5 of
                    the License Agreement) on several devices, provided that he does not use the Application
                    simultaneously on two or more Devices;
                </li>
                <li>
                    The User can use the Application provided under a free subscription (clauses 2-3 of Article 5 of the
                    License Agreement) on several devices without restriction.
                </li>
            </StyledUnorderedList>,
            '4. The user is not entitled (restrictions):',
            <StyledUnorderedList key={5}>
                <li>
                    sell, distribute, sublicense, assign license to use the Application without the written consent of
                    the Right Holder;
                </li>
                <li>copy the Application;</li>
                <li>use the Application in ways that are not provided for in the License Agreement;</li>
                <li>
                    use the Application in criminal and illegal activities, as well as for purposes contrary to the
                    License Agreement, or violating the rights of third parties (including intellectual property
                    rights);
                </li>
                <li>
                    decompile , disassemble, reverse engineer or create intellectual property based on the Application
                    or parts thereof;
                </li>
                <li>attempt to bypass the technical restrictions opereted in the Application;</li>
                <li>
                    use third-party programs or other tools to improve and to automate the functionality of the
                    Application;
                </li>
                <li>not restrict the use of the Application in any way;</li>
                <li>
                    distribute the content of the Application (including by distributing screenshots, screen recordings)
                    without the prior written permission of the Right Holder.
                </li>
            </StyledUnorderedList>,
            '5. The Right Holder has the right to terminate the License Agreement in the event of a violation by the User of one of the restrictions in accordance with Art. 10 of the License Agreement.',
        ],
    },

    {
        title: 'Article 4. Procedure for using the Application',
        paragraphs: [
            '1. To start using the Application, you need to download the installation file to your computer by clicking the "Download now" button and install the Application in accordance with the instructions.',
            '2. User Warranties:',
            <StyledUnorderedList key={2}>
                <li>
                    the characteristics of the User`s Device correspond to the characteristics indicated on the Site;
                </li>
                <li>the User is an adult in accordance with the laws of his place of residence;</li>
                <li>
                    by entering bank card details to pay for a subscription, the User confirms that he is its holder, or
                    an authorized representative of the card holder.
                </li>
            </StyledUnorderedList>,
            '3. The User must use the Application within the limits of the permitted use, in accordance with the restrictions set forth in the License Agreement.',
        ],
    },

    {
        title: 'Article 5. Subscription',
        paragraphs: [
            '1. The subscription is made in the Application by selecting the appropriate type of subscription (except for the free subscription):',
            <StyledUnorderedList key={1}>
                <li>subscription for a year;</li>
                <li>subscription for a month;</li>
                <li>permanent subscription.</li>
            </StyledUnorderedList>,
            <i key={2}>Free subscription</i>,
            '2. The free subscription starts from the moment the Application is installed on the Device.',
            '3. The free subscription functionality is listed in the Application.',
            <i key={5}>Paid subscription</i>,
            '4. The paid subscription functionality is listed in the Application.',
            '5. Period of validity:',
            <StyledUnorderedList key={8}>
                <li>subscription for a year - one year from the date of payment;</li>
                <li>subscription for a month - one month from the date of payment;</li>
                <li>permanent subscription.</li>
            </StyledUnorderedList>,
            '6. The User pays for the subscription depending on the selected validity period by entering the relevant data in the Application.',
            '7. The cost of the selected type of subscription is indicated in the Application.',
            [
                '8. Payment for a paid subscription is made by using the functionality of the Payment Provider. Paying for a subscription, the User also enters into an agreement with the Payment Provider. Your relationship with the Payment Provider is governed by ',
                <StyledHref key={1} href="https://www.paddle.com/legal/privacy" target={'_blank'} rel="noreferrer">
                    https://www.paddle.com/legal/privacy
                </StyledHref>,
            ],
            '9. The renewal fees are automatically charged on the last day of the subscription period. If the card details are invalid or there are not enough funds in the account to pay for the subscription, the Right Holder terminates access to the Application.',
            '10. Before renewing the Paid Subscription, the User has the right to cancel the Subscription for the next period no later than the penultimate day of the subscription period. In such case, the subscription will terminate on the last day of the Subscription period.',
            '11. If the User after terminating access to the Application, wants to resume access, he can do this in the Application.',
            [
                '12. Refunds are made according to the Refund Policy ',
                <StyledHref key={1} href="https://widgetter.com/refund-policy" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/refund-policy
                </StyledHref>,
            ],
            '13. The Right holder has the right to change the subscription price. The Right Holder increases the cost of the subscription only when the User makes a new subscription.',
            'The changed Subscription cost does not apply to the Subscriptions already active and paid by the User.',
        ],
    },

    {
        title: 'Article 6. Application Updates',
        paragraphs: [
            '1. The Right Holder updates the Applications at its own discretion.',
            '2. The Right Holder has the right to add or remove any functions of the Application at its own discretion with or without prior notice.',
            '3. The Right Holder has the right to send the User a notification about the need to update the Application due to the availability of a new version of the Application or automatic update of the Application.',
            '4. The Right Holder has the right not to support previous versions of the Application when updates or subsequent versions of the Application appear.',
            '5. The Right Holder has the right to make changes to the License Agreement in case of updating the Application (Article 2).',
        ],
    },

    {
        title: 'Article 7. Suspension of use of the Application',
        paragraphs: [
            '1. The Right Holder has the right to suspend / terminate the Application without prior notice to the User, compensation for losses, and without liability:',
            <StyledUnorderedList key={1}>
                <li>if it is necessary for error correction, maintenance or other similar actions;</li>
                <li>if the User has not paid for the renewal of the Subscription period;</li>
                <li>
                    if the User has violated the restrictions (Article 3), other conditions of the License Agreement.
                </li>
            </StyledUnorderedList>,
        ],
    },

    {
        title: 'Article 8. Personal data',
        paragraphs: [
            '1. The User confirms that he has read the Privacy Policy and agreed with it.',
            '2. The Right Holder collects and uses technical and related information to improve the operation of the Application. The Right Holder cannot identify the User from this information.',
            '3. The Right holder uses the following services for collecting technical and related information:',
            <StyledTable key={3}>
                <thead>
                    <tr>
                        <StyledTH width="200px">
                            <Text fontWeight="700">Service name</Text>
                        </StyledTH>
                        <StyledTH>
                            <Text fontWeight="700">Type of processed information</Text>
                        </StyledTH>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <StyledTH>Amplitude</StyledTH>
                        <StyledTH>The User actions in the Application and the operation of the Application</StyledTH>
                    </tr>
                    <tr>
                        <StyledTH>RevenueCat</StyledTH>
                        <StyledTH>
                            Information about payments made by the User for subscriptions, subscription renewal, number
                            of payments.
                        </StyledTH>
                    </tr>
                    <tr>
                        <StyledTH>Firebase</StyledTH>
                        <StyledTH>The User actions in the Application and the operation of the Application</StyledTH>
                    </tr>
                    <tr>
                        <StyledTH>BugSnag</StyledTH>
                        <StyledTH>Application Errors</StyledTH>
                    </tr>
                    <tr>
                        <StyledTH>Doorbell</StyledTH>
                        <StyledTH>Feedback from the User regarding the operation of the Application</StyledTH>
                    </tr>
                </tbody>
            </StyledTable>,
        ],
    },

    {
        title: 'Article 9. Liability',
        paragraphs: [
            '1. The application is provided "as is".',
            '2. The Right Holder does not guarantee that the Application meets the User`s expectations, is provided continuously, quickly, reliably and error-free, and will be available at any time.',
            '3. The Right holder is not responsible for:',
            <StyledUnorderedList key={3}>
                <li>
                    specialized resources and quality of channels of public communication networks through which the
                    Application is accessed, as well as those caused by interruptions in the provision of services by
                    contractors and third parties;
                </li>
                <li>breakdowns or other malfunctions of the Device that occurred during the use of the Application;</li>
                <li>
                    the consequences caused by the loss or disclosure by the User of his data necessary to access the
                    Application;
                </li>
                <li>
                    errors and / or violations related to the use of the Application and arising due to illegal actions
                    of third parties;
                </li>
                <li>
                    failures and interruptions in the operation of the Application caused by force majeure
                    circumstances;
                </li>
                <li>
                    failures and interruptions in the operation of the Application caused by the actions of state
                    authorities, including law enforcement agencies, to seize and / or confiscate or otherwise obstruct
                    access to the servers of counterparties hosting the Application, and due to the imposition of
                    sanctions.
                </li>
            </StyledUnorderedList>,
            '4. The responsibility of the Right Holder cannot exceed the amount of the payment transferred by the User.',
        ],
    },

    {
        title: 'Article 10. Termination of the License Agreement',
        paragraphs: [
            <i key={0}>Validity</i>,
            '1. The term of the License Agreement corresponds to the term of the subscription.',
            <i key={2}>Termination</i>,
            '2. The User has the right to refuse the terms of the License Agreement by deleting the Application from the Device.',
            '3. The Right Holder has the right to terminate the License Agreement in the event of:',
            <StyledUnorderedList key={5}>
                <li>violation by the User of the terms of the License Agreement (including restrictions);</li>
                <li>
                    the obligation of the Right Holder in accordance with the law or the decision of the authorized
                    body.
                </li>
            </StyledUnorderedList>,
            'In this case, the Right Holder terminates the operation of the Application on the User`s device.',
        ],
    },

    {
        title: 'Article 11. Settlement of disputes',
        paragraphs: [
            '1. The User and the Right Holder are obliged to comply with the pre-trial (claim) procedure for resolving disputes.',
            '2. The term for responding to a claim is 10 (ten) business days from the date of its receipt by the recipient Party.',
            '3. If it is impossible to resolve the dispute in a claim procedure, the dispute is subject to consideration in court in accordance with the legislation of the republic Kazakhstan.',
            '4. When interpreting the terms of the Agreement and resolving disputes, the law of the republic Kazakhstan shall apply.',
        ],
    },

    {
        title: 'Article 12. Final provisions',
        paragraphs: [
            '1. The license agreement is in English. In the event of any discrepancy between the translated and the English version, the English version shall apply.',
            '2. Recognition of any condition as invalid does not mean the invalidation of the entire License Agreement.',
            [
                'The current version of the License Agreement is posted on the page at: ',
                <StyledHref key={1} href="https://widgetter.com/terms" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/terms
                </StyledHref>,
            ],
        ],
    },

    {
        title: 'Contact details:',
        paragraphs: [
            'Bitnite, LLP.',
            'BIN: 220840009542',
            'The Republic of Kazakhstan. Almaty city, st. Zhandosova 1/1, (Business center "Success"), 2nd floor.',
        ],
    },
];