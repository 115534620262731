import { DESCRIPTION, STORY_TIME } from 'constants/storiesSwitcher';

import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BACKGROUNDS } from 'assets/backgrounds';
import { useWindowSizeContext } from 'context/WindowSizeContext';

import { Text } from 'components/Text';

import {
    StyledBackground,
    StyledHeader,
    StyledHeaderWrap,
    StyledLine,
    StyledLines,
    StyledLineWrap,
    StyledProgress,
    StyledStoriesSwitcherComponent,
    StyledStoriesWrap,
    StyledTextContainer,
    StyledTitle,
    StyledTouchScreen,
} from './StoriesSwitcher.style';

export const StoriesSwitcher: React.FC = () => {
    const { isMobileSize } = useWindowSizeContext();
    const [currentIndex, setCurrentIndex] = useState(0);
    const nextStory = useCallback(() => {
        setCurrentIndex((prev) => (prev + 1) % BACKGROUNDS.length);
    }, []);
    const previousStory = useCallback(() => {
        setCurrentIndex((prev) => (prev - 1 + BACKGROUNDS.length) % BACKGROUNDS.length);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prev) => (prev + 1) % BACKGROUNDS.length);
        }, STORY_TIME * 1000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    });
    return (
        <StyledStoriesSwitcherComponent>
            <StyledStoriesWrap>
                {BACKGROUNDS.map((item, ind) => (
                    <StyledBackground
                        key={ind}
                        src={isMobileSize ? item[1] : item[0]}
                        positionNumber={ind}
                        activeIndex={currentIndex}
                    />
                ))}
                {isMobile && (
                    <>
                        <StyledTouchScreen rightSide={true} onClick={nextStory} />
                        <StyledTouchScreen rightSide={false} onClick={previousStory} />
                    </>
                )}
            </StyledStoriesWrap>
            <StyledHeaderWrap>
                <StyledHeader>
                    <StyledLines>
                        {BACKGROUNDS.map((_, ind) => (
                            <StyledLineWrap
                                key={ind}
                                positionNumber={ind}
                                count={BACKGROUNDS.length}
                                onClick={!isMobile ? () => setCurrentIndex(ind) : undefined}
                                isMobile={isMobile}
                            >
                                <StyledLine>
                                    <StyledProgress positionNumber={ind} activeIndex={currentIndex} time={STORY_TIME} />
                                </StyledLine>
                            </StyledLineWrap>
                        ))}
                    </StyledLines>
                    <StyledTextContainer>
                        <StyledTitle fontWeight="500" fontSize="24px" lineHeight="24px">
                            HD 5k and 4k wallpapers for every taste
                        </StyledTitle>
                        <Text fontWeight="400" fontSize="14px" lineHeight="20px">
                            {DESCRIPTION}
                        </Text>
                    </StyledTextContainer>
                </StyledHeader>
            </StyledHeaderWrap>
        </StyledStoriesSwitcherComponent>
    );
};
