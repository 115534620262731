import React from 'react';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';

import { PRIVACY } from './PrivacyPage.constants';
import { StyledParagraphs, StyledPrivacy, StyledPrivacyPage, StyledSubTitle, StyledTitle } from './PrivacyPage.style';

export const PrivacyPage: React.FC = () => {
    return (
        <StyledPrivacyPage>
            <StyledPrivacy>
                <StyledTitle>
                    <div>
                        <Text fontWeight="700" fontSize="32px" lineHeight="38px">
                            Privacy Policy
                        </Text>
                    </div>
                    <div>
                        <Text type={TextTypes.BODY16} lineHeight="1.75">
                            Version effective as of December 04, 2022.
                        </Text>
                    </div>
                </StyledTitle>
                {PRIVACY.map((item) => (
                    <>
                        <StyledSubTitle>
                            <Text type={TextTypes.TITLE16}>{item.title}</Text>
                        </StyledSubTitle>

                        {item.paragraphs.map((paragraph, pInd) => (
                            <StyledParagraphs key={pInd} numberOfLine={pInd} countOfLines={item.paragraphs.length}>
                                <Text type={TextTypes.BODY16} lineHeight="1.75">
                                    {paragraph}
                                </Text>
                            </StyledParagraphs>
                        ))}
                    </>
                ))}
            </StyledPrivacy>
        </StyledPrivacyPage>
    );
};