import { COLORS } from 'constants/colors';

import { GeoIcon, QuotationMarksIcon } from 'assets';
import styled from 'styled-components';

import { Text } from 'components/Text';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledQuotationMarksIcon = styled(QuotationMarksIcon)`
    position: absolute;
`;
export const StyledQuotationMarksIconOpinion = styled(QuotationMarksIcon)`
    position: absolute;
    top: -92px;
    left: -84px;

    @media (max-width: 1250px) {
        left: -40px;
    }
    @media (max-width: 1000px) {
        top: -125px;
    }
    @media (max-width: 412px) {
        top: -172px;
    }
`;
export const StyledGeoIcon = styled(GeoIcon)`
    padding-left: 8px;
    fill: ${hexToRgba(COLORS.white, 0.5)};
`;

export const StyledTestimonialsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
`;
export const StyledTestimonials = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1110px;
    margin: 0px 24px;
    margin-top: 188px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const StyledTestimonialsColumns = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    max-width: 555px;
    width: 100%;

    @media (max-width: 1000px) {
        max-width: none;
    }
`;
export const StyledTestimonial = styled.div<{ column: number }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 32px;
    margin: 16px 15px;
    ${({ column }) => (column === 0 ? 'margin-left' : 'margin-right')};
    background-color: ${hexToRgba(COLORS.white, 0.02)};

    &:hover {
        background-color: ${hexToRgba(COLORS.white, 0.05)};
    }

    @media (max-width: 1000px) {
        margin: 16px 0px;
    }
`;

export const StyledContent = styled.div`
    padding-left: 32px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
`;

export const StyledSignature = styled.div`
    padding-left: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledOpinion = styled.div`
    position: absolute;
    top: -62px;
    left: 12px;
    max-width: 442px;

    @media (max-width: 1000px) {
        top: -114px;
    }
    @media (max-width: 412px) {
        top: -166px;
    }
`;

export const StyledBottom = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 24px;
    margin-top: 139px;
    margin-bottom: 94px;
    max-width: 652px;

    @media (max-width: 1000px) {
        margin: 24px;
    }
`;

export const StyledTitleBottom = styled(Text)`
    margin-bottom: 24px;

    @media (max-width: 1000px) {
        margin-bottom: 16px;
    }
`;
export const StyledCircleShadow = styled.div`
    position: absolute;
    top: 240px;
    width: 69px;
    height: 69px;
    border-radius: 50%;
    z-index: 0;
    background-color: ${hexToRgba('#505050', 0.22)};
    backdrop-filter: blur(256px);
    box-shadow: 0px 0px 100px 100px ${hexToRgba('#505050', 0.22)};

    @media (max-width: 1000px) {
        opacity: 0;
    }
`;
