import React from 'react';

import { StyledHref, StyledUnorderedList } from './RefundPrivacyPage.style';

export const PRIVACY = [
    {
        title: 'Article 1. Definitions',
        paragraphs: [
            'For the purposes of Refunds Policy, the terms have the following meanings:',
            '1. Widgetter / application - software (application) that allows the User to optimize the working space on the computer, including optimization of controls, collection of basic information about the computer perfomance.',
            [
                '2. Website - website ',
                <StyledHref key={1} href="https://widgetter.com/" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/
                </StyledHref>,
                ' from which the User can download the Application. The Site also includes all subdomains of the Site.',
            ],
            '3. Subscription - the number of rights granted to the User for a fee or free of charge, depending on the functionality of the Application provided to the User.',
            '4. User – an individual using the Application.',
            '5. Device - Mac (any model) legally owned by the User.',
            '6. The right holder is Bitnite LLP. BIN: 220840009542.',
            '7. The Payment Provider – the Legal Entity Paddle LLC, authorized by the Right holder to accept payments from the User.',
        ],
    },

    {
        title: 'Article 2. The right of the User to cancel the subscription',
        paragraphs: [
            'The User has the right to demand the return payments for the paid subscription if the Application does not suit the User. The User has the right to demand the return payments, if the request is sent within 10 days from the date of payment.',
            [
                'The user sends a request to the email: ',
                <StyledHref key={1} href="mailto:support@bitnite.studio" target={'_blank'} rel="noreferrer">
                    support@bitnite.studio
                </StyledHref>,
            ],
            'The Right holder is not responsible for the payment deadlines, because payments are accepted by the Payment Provider.',
            'After receiving the funds, the User must immediately stop using the Application. The Right holder has the right to prevent the User`s access to the Application within 1 day from the date of receiving information from the Payment Provider about the return of funds.',
        ],
    },

    {
        title: 'Article 3. Non-refundable cases',
        paragraphs: [
            'The Right holder has the right to reject the request to return payments in the following cases:',
            <StyledUnorderedList key={1}>
                <li>The app was purchased for the wrong platform.</li>
                <li>Inability to work with the Application in the User`s computing environment.</li>
                <li>Inability to use the Application due to defects in the Device.</li>
            </StyledUnorderedList>,
            'The Right holder does not refund or credit the difference between the amount paid by the User and a price reduction that is limited in time, for example, during special sales.',
        ],
    },

    {
        title: 'Contact details:',
        paragraphs: [
            'Bitnite, LLP.',
            'BIN: 220840009542',
            'The Republic of Kazakhstan. Almaty city, st. Zhandosova 1/1, (Business center "Success"), 2nd floor.',
        ],
    },
];