import { useCallback, useEffect } from 'react';

export const useArrowHandler = (func: React.Dispatch<React.SetStateAction<number>>, count: number, flag: boolean) => {
    const changeCurrentTheme = useCallback(
        (event: KeyboardEvent) => {
            switch (event.key) {
                case 'ArrowLeft':
                    func((prev) => Math.max(0, prev - 1));
                    break;
                case 'ArrowRight':
                    func((prev) => (prev + 1) % count);
                    break;
            }
            event.preventDefault();
        },
        [func, count],
    );
    useEffect(() => {
        if (flag) {
            window.addEventListener('keydown', changeCurrentTheme);
        } else {
            window.removeEventListener('keydown', changeCurrentTheme);
        }
        return () => {
            window.removeEventListener('keydown', changeCurrentTheme);
        };
    }, [changeCurrentTheme, flag]);
};
