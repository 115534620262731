import { COLORS } from 'constants/colors';

import styled from 'styled-components';

import { Text } from 'components/Text';

export const StyledTermsPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 72px - 208px);
    overflow: hidden;
    width: 100%;
`;

export const StyledTerms = styled.div`
    margin: 124px 165px 121px 165px;
    width: calc(100% - 330px);

    @media (max-width: 1000px) {
        margin: 56px 24px;
        width: calc(100% - 48px);
    }
`;

export const StyledTitle = styled.div`
    padding-bottom: 12px;
`;

export const StyledSubTitle = styled.div`
    padding-top: 12px;
`;

export const StyledParagraphs = styled.div<{ numberOfLine: number; countOfLines: number }>`
    ${({ numberOfLine, countOfLines }) => numberOfLine === countOfLines - 1 && 'padding-bottom:12px'};
    ${({ numberOfLine }) => numberOfLine === 0 && 'padding-top:12px'};
`;

export const StyledContactMobile = styled(Text)`
    color: ${COLORS.yellow};

    @media (min-width: 1001px) {
        display: none;
    }
`;
export const StyledHref = styled.a`
    text-decoration: none;
    color: rgb(86, 86, 255);
`;

export const StyledUnorderedList = styled.ul`
    padding: 0px;
    padding-left: 20px;
    margin: 0;
`;

export const StyledTable = styled.table`
    margin-top: 20px;
    border: 1px solid ${COLORS.white};
    border-collapse: collapse;
`;

export const StyledTH = styled.th<{ width?: string }>`
    ${({ width }) => width && `width: ${width};`}
    border: 1px solid ${COLORS.white};
    font-weight: 400;
    padding: 12px;
    vertical-align: baseline;
`;