export enum WidgetWrapperDiraction {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface WidgetWrapperProps {
    diraction: WidgetWrapperDiraction;
    title: string;
    description: string;
    widget: string;
    top: number;
    left?: number;
    right?: number;
    elemHover: boolean;
    setElemHover: React.Dispatch<React.SetStateAction<boolean>>;
}
