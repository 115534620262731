import { COLORS } from 'constants/colors';

import { isMobileOnly } from 'react-device-detect';
import { ArrowIcon } from 'assets';
import styled from 'styled-components';

import { Text } from 'components/Text';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;
export const StyledSlider = styled.div`
    width: 100%;
    max-width: 1385px;
    align-items: center;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
`;

export const StyledWallpapers = styled.div`
    margin: 24px 24px 74px 24px;
    position: relative;

    ${isMobileOnly && 'margin: 16px 0px 32px 0px;'}
`;

export const StyledText = styled.div`
    margin: 58px 24px 0px 24px;
    max-width: 1110px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        margin-top: 32px;
    }
`;

export const StyledWallpaperContainer = styled.div`
    margin-left: calc(50% - 311px - 243px);

    @media (max-width: 1158px) {
        margin-left: 0;
    }
`;

export const StyledWallpaperImg = styled.img<{
    activeIndex: number;
    positionNumber: number;
    count: number;
    width: number;
}>`
    display: inline-flex;
    width: 622px;
    height: 350px;
    margin-right: 24px;
    margin-left: ${({ positionNumber }) => (positionNumber === 0 ? 0 : 24)}px;
    border-radius: 10px;
    transition: transform 0.4s ease-out;
    cursor: pointer;

    ${isMobileOnly &&
    ` 
        cursor: default;
        margin-left:24px;
    `}
`;

export const StyledTest = styled.div`
    white-space: nowrap;
    width: 100%;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        width: 0;
    }
`;

export const StyledGradient = styled.div<{ positionRight?: boolean }>`
    position: absolute;
    ${({ positionRight }) => (positionRight ? 'right: -1px' : 'left: -1px')};
    top: 0;
    background: -webkit-linear-gradient(
        ${({ positionRight }) => (positionRight ? 'left' : 'right')},
        ${hexToRgba(COLORS.background, 0)} 0%,
        ${hexToRgba(COLORS.background, 0.65)} 55%,
        ${hexToRgba(COLORS.background)} 100%
    );
    background: linear-gradient(
        ${({ positionRight }) => (positionRight ? '' : '-')}90deg,
        ${hexToRgba(COLORS.background, 0)} 0%,
        ${hexToRgba(COLORS.background, 0.65)} 55%,
        ${COLORS.background} 100%
    );
    height: 100%;
    width: 120px;
    cursor: pointer;

    @media (max-width: 1000px) {
        display: none;
    }
`;

export const StyledArrowRightIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 20px);
    right: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${COLORS.background};
    cursor: pointer;
`;

export const StyledArrowLeftIcon = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 20px);
    left: -10px;
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
    border-radius: 50%;
    background-color: ${COLORS.background};
    cursor: pointer;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
    border-radius: 50%;
    background-color: ${hexToRgba(COLORS.white, 0.06)};

    &:hover {
        background-color: ${hexToRgba(COLORS.white, 0.1)};
    }
`;

export const StyledDescriptionText = styled(Text)`
    margin-top: 8px;

    @media (max-width: 1000px) {
        display: none;
    }
`;
