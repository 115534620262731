import { useCallback, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

export const useCloserHandler = (
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
    firstThemeRef: React.RefObject<HTMLDivElement>,
    sliderRef: React.RefObject<HTMLDivElement>,
    width: number,
    count: number,
    currentIndex: number,
    setInProgress: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const [autoCloser, setAutoCloser] = useState<NodeJS.Timeout>(setTimeout(() => undefined));

    const closerFunc = useCallback(() => {
        setInProgress(true);
        window.clearTimeout(autoCloser);
        setAutoCloser(
            setTimeout(() => {
                const current = firstThemeRef.current as Element;
                const firstThemeX = current.getBoundingClientRect().x;
                const minDist: {
                    indOfTheme: number;
                    distance: number;
                } = { indOfTheme: -1, distance: Infinity };
                for (let ind = 0; ind < count; ind++) {
                    const elementX =
                        -Math.max((width - 1385) / 2, 0) -
                        Math.max(width / 2 - 578 - Math.max((width - 1385) / 2, 0), 0) -
                        (isDesktop ? 24 : 0) +
                        (ind === 0
                            ? 0
                            : (width > 1158 ? 427 : 1030 - width / 2 - 24) + (ind === 1 ? 0 : (ind - 1) * 670));

                    if (Math.abs(firstThemeX + elementX) < Math.abs(minDist.distance)) {
                        minDist.indOfTheme = ind;
                        minDist.distance = elementX + firstThemeX;
                    }
                }

                if (currentIndex === minDist.indOfTheme) {
                    const slider = sliderRef.current as Element;
                    slider.scrollBy({ top: 0, left: minDist.distance, behavior: 'smooth' });
                } else {
                    setActiveIndex(minDist.indOfTheme);
                }
                setInProgress(false);
            }, 500),
        );
    }, [autoCloser, firstThemeRef, currentIndex, setInProgress, count, width, sliderRef, setActiveIndex]);

    useEffect(() => {
        const slider = sliderRef.current as Element;

        slider.addEventListener('scroll', closerFunc);

        return () => {
            slider.removeEventListener('scroll', closerFunc);
        };
    }, [closerFunc, firstThemeRef, sliderRef]);
};

export const useSwipeEnd = (
    setInProgress: React.Dispatch<React.SetStateAction<boolean>>,
    setDrag: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const swipeEnd = useCallback(
        (event: Event) => {
            setInProgress(false);
            setDrag(false);
            event.preventDefault();
        },
        [setDrag, setInProgress],
    );
    useEffect(() => {
        window.addEventListener('mouseup', swipeEnd);

        return () => {
            window.removeEventListener('mouseup', swipeEnd);
        };
    }, [swipeEnd]);
};
