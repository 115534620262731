export enum TextTypes {
    TITLE16 = 'Title16',
    BODY14 = 'Body14',
    BODY16 = 'Body16',
}

export interface TextProps {
    color?: string;
    type?: TextTypes;
    margin?: string;
    textDecoration?: string;
    mixin?: string;
    letterSpacing?: string;
    fontSize?: string;
    fontWeight?: string;
    lineHeight?: string;
    textAlign?: string;
}
