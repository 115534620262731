import React, { useCallback, useEffect, useState } from 'react';

import { MacBook } from 'components/MacBook';
import { Previewer } from 'components/Previewer';
import { Slider } from 'components/Slider';
import { StoriesSwitcher } from 'components/StoriesSwitcher';
import { TestimonialsContainer } from 'components/TestimonialsContainer';
import { TilesContainer } from 'components/TilesContainer';

export const MainPage = React.forwardRef<HTMLAnchorElement>((_, ref) => {
    const [visiblePreviewer, setVisiblePreviewer] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [mouseSlide, setMouseSlide] = useState(false);
    useEffect(() => {
        if (visiblePreviewer) {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }, [visiblePreviewer]);

    const setVisiblePreviewerCallBack = useCallback(
        (ind: number) => {
            if (!mouseSlide) {
                setActiveIndex(ind);
                setVisiblePreviewer(true);
            }
            setMouseSlide(false);
        },
        [mouseSlide],
    );
    return (
        <>
            <MacBook ref={ref} />
            <Slider
                setVisiblePreviewer={setVisiblePreviewerCallBack}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                visiblePreviewer={visiblePreviewer}
                setMouseSlide={setMouseSlide}
            />
            <Previewer
                visiblePreviewer={visiblePreviewer}
                setVisiblePreviewer={setVisiblePreviewer}
                activeIndex={activeIndex}
            />
            <TilesContainer />
            <StoriesSwitcher />
            <TestimonialsContainer />
        </>
    );
});
