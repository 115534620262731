import { COLORS } from 'constants/colors';

import React from 'react';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';

import { StyledButtonComponent } from './Button.style';
import { ButtonProps } from './Button.types';

export const Button = React.forwardRef<HTMLAnchorElement, ButtonProps>((props, ref) => {
    return (
        <StyledButtonComponent
            ref={ref}
            href={props.href}
            target={'_blank'}
            backgroundColor={props.backgroundColor}
            {...props}
        >
            <Text type={TextTypes.TITLE16} color={props.backgroundColor !== COLORS.blue ? COLORS.black : COLORS.white}>
                {props.label}
            </Text>
        </StyledButtonComponent>
    );
});
