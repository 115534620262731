import styled from 'styled-components';

import { Button } from 'components/Button';
import { Text } from 'components/Text';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledMacBookComponent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const StyledMacBook = styled.div`
    position: relative;
    width: 946px;
    height: 578px;
    margin-bottom: 67px;
    margin-top: 80px;

    @media (max-width: 1000px) {
        width: calc(100% - 46px);
        height: min-content;
        margin-bottom: 71px;
        margin-top: 42px;
        height: auto;
    }
`;

export const StyledMac = styled.img`
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
`;

export const StyledMacBookWithWidgets = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
`;

export const StyledOrangeCircle = styled.div`
    position: absolute;
    top: -15px;
    left: -30px;
    z-index: 0;
    border-radius: 50%;
    width: 462px;
    height: 462px;
    background-color: ${hexToRgba('#FF8126', 0.3)};
    opacity: 0.5;
    filter: blur(380px);
    // ! NEVER REMOVE
    // ! filter blur causes performance bugs in Safari
    transform: translate3d(0, 0, 0);
`;

export const StyledBlueCircle = styled.div`
    position: absolute;
    top: -129px;
    right: -5px;
    z-index: 0;
    border-radius: 50%;
    width: 499px;
    height: 499px;
    background-color: ${hexToRgba('#00A3FF', 0.4)};
    opacity: 0.5;
    filter: blur(360px);
    // ! NEVER REMOVE
    // ! filter blur causes performance bugs in Safari
    transform: translate3d(0, 0, 0);
`;

export const StyledTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 84px 72px 32px 72px;
    max-width: 770px;
    width: calc(100% - 48px);

    @media (max-width: 1000px) {
        align-items: start;
        margin: 56px 24px 0px 24px;
    }
`;

export const StyledDescriptionText = styled(Text)`
    margin-top: 16px;

    @media (min-width: 1001px) {
        text-align: center;
    }
`;

export const StyledButton = styled(Button)`
    @media (max-width: 1000px) {
        display: none;
    }
`;
