import { COLORS } from 'constants/colors';

import React, { useCallback } from 'react';

import { Text } from 'components/Text';
import { hexToRgba } from 'utils/hexToRgba';

import { StyledDescription, StyledWidget, StyledWidgetWrapperContainer, WidgetWrappers } from './WidgetWrapper.style';
import { WidgetWrapperDiraction, WidgetWrapperProps } from './WidgetWrapper.types';

export const WidgetWrapper: React.FC<WidgetWrapperProps> = ({
    diraction,
    title,
    description,
    widget,
    top,
    left,
    right,
    elemHover,
    setElemHover,
}) => {
    const handleMouseEnterEvent = useCallback(() => {
        setElemHover(true);
    }, [setElemHover]);
    const handleMouseLeaveEvent = useCallback(() => {
        setElemHover(false);
    }, [setElemHover]);

    return (
        <StyledWidgetWrapperContainer elemHover={elemHover} top={top} right={right} left={left}>
            <WidgetWrappers rightSide={diraction === WidgetWrapperDiraction.RIGHT}>
                <StyledWidget src={widget} onMouseEnter={handleMouseEnterEvent} onMouseLeave={handleMouseLeaveEvent} />
                <StyledDescription>
                    <div>
                        <Text fontWeight="600" fontSize="16px" lineHeight="24px">
                            {title}
                        </Text>
                    </div>
                    <div>
                        <Text color={hexToRgba(COLORS.white, 0.75)} fontWeight="400" fontSize="14px" lineHeight="20px">
                            {description}
                        </Text>
                    </div>
                </StyledDescription>
            </WidgetWrappers>
        </StyledWidgetWrapperContainer>
    );
};
