import { createContext, useContext } from 'react';

export const WindowSizeContext = createContext({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    isMobileSize: document.documentElement.clientWidth <= 1000,
});

export function useWindowSizeContext() {
    return useContext(WindowSizeContext);
}
