import { COLORS } from 'constants/colors';

import { Link } from 'react-router-dom';
import { textTypeStyles } from 'mixins/TextTypeStyles';
import styled from 'styled-components';

import { Button } from 'components/Button';
import { TextTypes } from 'components/Text/Text.types';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledHeaderComponent = styled.div`
    position: sticky;
    overflow: visible;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    background-color: ${hexToRgba('#0D0E0E', 0.9)};
    backdrop-filter: blur(25px);
`;
export const StyledHEader = styled.div`
    max-width: 1376px;
    width: 100%;
    height: 100%;
    margin: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const StyledMailHref = styled.a`
    text-decoration: none;
    color: ${COLORS.white};
    ${textTypeStyles(TextTypes.TITLE16)};
    margin-left: 32px;
`;
export const StyledLink = styled(Link)`
    text-decoration: none;
    display: flex;
    align-items: center;
`;

export const StyledContacts = styled.div`
    display: flex;
    align-items: center;
`;
export const StyledTopButton = styled(Button)`
    @media (max-width: 1000px) {
        display: none;
    }
`;
export const StyledBottomButtonContainer = styled.div`
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;
    background-color: ${hexToRgba('#0D0E0E', 0.9)};
    backdrop-filter: blur(25px);
    display: none;

    @media (max-width: 1000px) {
        display: flex;
    }
`;
export const StyledBottomButton = styled(Button)`
    width: 100%;
    margin: 8px 24px 32px 24px;
`;

export const StyledChildren = styled.div`
    overflow: hidden;
`;
