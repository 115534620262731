import { COLORS } from 'constants/colors';

import styled from 'styled-components';

import { hexToRgba } from 'utils/hexToRgba';

export const StyledDescription = styled.div`
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 224px;
    opacity: 0;
    transition: opacity 0.15s linear;
`;

export const StyledWidget = styled.img`
    &:hover ~ div {
        visibility: visible;
        opacity: 1;
    }
`;

export const StyledWidgetWrapperContainer = styled.div<{
    elemHover: boolean;
    top: number;
    left?: number;
    right?: number;
}>`
    position: absolute;
    top: ${({ top }) => top}px;
    ${({ left }) => left !== undefined && `left: ${left}px`};
    ${({ right }) => right !== undefined && `right: ${right}px`};
    border-radius: 48px;
    ${({ elemHover }) => elemHover && 'opacity: 0.3'};
    transition: opacity 0.15s linear;

    ${StyledWidget}:hover {
        background-color: ${hexToRgba(COLORS.black, 0.5)};
        box-shadow: 0px 0px 50px 50px rgba(0, 0, 0, 0.5);
    }
    &:hover {
        opacity: 1;
    }
`;

export const WidgetWrappers = styled.div<{ rightSide?: boolean }>`
    display: flex;
    flex-direction: ${({ rightSide }) => (rightSide ? 'row' : 'row-reverse')};
    align-items: flex-start;
`;
