import { TILES } from 'constants/tiles';

import React from 'react';

import { Text } from 'components/Text';

import {
    StyledDescription,
    StyledIcon,
    StyledTextBox,
    StyledTile,
    StyledTiles,
    StyledTilesComponent,
    StyledTilesPage,
    StyledTitle,
} from './TilesContainer.style';

export const TilesContainer: React.FC = () => {
    return (
        <StyledTilesPage>
            <StyledTilesComponent>
                <Text fontWeight={'500'} fontSize={'24px'} lineHeight={'24px'}>
                    Various Mac Widgets
                </Text>
                <StyledTiles>
                    {TILES.map((tile, index) => (
                        <StyledTile key={index}>
                            <StyledIcon>{tile.children}</StyledIcon>
                            <StyledTextBox>
                                <StyledTitle>{tile.title}</StyledTitle>
                                <StyledDescription>{tile.description}</StyledDescription>
                            </StyledTextBox>
                        </StyledTile>
                    ))}
                </StyledTiles>
            </StyledTilesComponent>
        </StyledTilesPage>
    );
};
