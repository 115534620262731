import { COLORS } from 'constants/colors';

import React, { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { LogoIcon } from 'assets';

import {
    StyledBottomButton,
    StyledBottomButtonContainer,
    StyledChildren,
    StyledContacts,
    StyledHEader,
    StyledHeaderComponent,
    StyledLink,
    StyledMailHref,
    StyledTopButton,
} from './Header.style';
import { HeaderProps } from './Header.types';

export const Header: React.FC<HeaderProps> = ({ isVisible, children }) => {
    const location = useLocation();
    const scrollUp = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <StyledHeaderComponent>
                <StyledHEader>
                    <StyledLink to="/" onClick={scrollUp}>
                        <LogoIcon fill={COLORS.yellow} width={171} height={38} />
                    </StyledLink>

                    <StyledContacts>
                        {(isVisible || location.pathname !== '/') && (
                            <div
                                onClick={() => {
                                    ReactGA.event('download_widgetter', {
                                        source: 'top',
                                    });
                                }}
                            >
                                <StyledTopButton
                                    width={156}
                                    height={36}
                                    borderRadius={8}
                                    backgroundColor={COLORS.blue}
                                    backgroundColorHover={COLORS.blueHover}
                                    href={'https://widgetter.com/download'}
                                    label={'Download Now'}
                                />
                            </div>
                        )}
                        <StyledMailHref href="mailto:support@bitnite.studio">Contact us</StyledMailHref>
                    </StyledContacts>
                </StyledHEader>
            </StyledHeaderComponent>
            <StyledChildren>{children}</StyledChildren>
            {location.pathname === '/' && (
                <StyledBottomButtonContainer
                    onClick={() => {
                        ReactGA.event('download', {
                            source: 'mobile',
                        });
                    }}
                >
                    <StyledBottomButton
                        height={64}
                        borderRadius={12}
                        backgroundColor={COLORS.blue}
                        backgroundColorHover={COLORS.blueHover}
                        href={'https://apps.apple.com/app/id1553223588'}
                        label={'Download Now'}
                    />
                </StyledBottomButtonContainer>
            )}
        </>
    );
};
