import React from 'react';

import { Text } from 'components/Text';

import { ScrollContainer, StyledHref, StyledTable, StyledTH, StyledUnorderedList } from './PrivacyPage.style';

export const PRIVACY = [
    {
        title: 'Article 1. Preamble',
        paragraphs: [
            'This privacy policy describes the collection, use and disclosure of the Users` information, the rights of Users and the obligations of the Controller.',
            'Using the Application, accessing the site, the User agrees to the terms of the Privacy Policy, as well as the collection and use of information by the Controller in accordance with the Privacy Policy.',
            [
                'The User is notified that the use of the Application is carried out in accordance with the License Agreement ',
                <StyledHref key={1} href="https://widgetter.com/terms" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/terms
                </StyledHref>,
            ],
        ],
    },

    {
        title: 'Article 2. Terms and definitions',
        paragraphs: [
            '1. The data controller is Bitnite LLP. BIN: 220840009542.',
            '2. User – an individual using the Application.',
            '3. Application – software (application)/software (applications), the Right holder of which is the Data Controller.',
            [
                'Website – website ',
                <StyledHref key={1} href="https://widgetter.com/" target={'_blank'} rel="noreferrer">
                    https://widgetter.com/
                </StyledHref>,
                ' from which the User can download the Application. The Site also includes all subdomains of the Site.',
            ],
            '5. The Payment Provider – the Legal Entity Paddle LLC, authorized by the Copyright Holder to accept payments from the User.',
            '6. A cookie - is a file containing an identifier (a string of letters and numbers) that is sent by a web server to and stored in a web browser. The file does not contain any information that can be used to identity the User, as well as the visitor to the Site.',
        ],
    },

    {
        title: 'Article 3. Data collected by the controller',
        paragraphs: [
            'The table below defines the categories of personal data processed by the Controller:',
            <ScrollContainer key={3}>
                <StyledTable>
                    <thead>
                        <tr>
                            <StyledTH>
                                <Text fontWeight="700">When data is collected</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Category of personal data</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Collected data</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Source</Text>
                            </StyledTH>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <StyledTH withoutBoards>When the User visits the Site</StyledTH>
                            <StyledTH>Technical details</StyledTH>
                            <StyledTH>
                                IP address, User Agent, Screen (screen resolution and device orientation)
                            </StyledTH>
                            <StyledTH>From browser</StyledTH>
                        </tr>
                        <tr>
                            <th></th>
                            <StyledTH>Cookies</StyledTH>
                            <StyledTH>Read more in Article 13 of the Privacy Policy</StyledTH>
                            <StyledTH>From browser</StyledTH>
                        </tr>
                        <tr>
                            <StyledTH withoutBoards>When the User uses the Application</StyledTH>
                            <StyledTH>Identification information</StyledTH>
                            <StyledTH>IP address</StyledTH>
                            <StyledTH>Servers and Controller systems</StyledTH>
                        </tr>
                        <tr>
                            <th></th>
                            <StyledTH>Technical and related information</StyledTH>
                            <StyledTH>Read more in Article 12 of the Privacy Policy, Email</StyledTH>
                            <StyledTH>Servers and Controller systems</StyledTH>
                        </tr>
                    </tbody>
                </StyledTable>
            </ScrollContainer>,
        ],
    },

    {
        title: 'Article 4. Why the Controller uses the User`s personal data',
        paragraphs: [
            <ScrollContainer key={0}>
                <StyledTable>
                    <thead>
                        <tr>
                            <StyledTH>
                                <Text fontWeight="700">Purpose of processing</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Category of personal data processed</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Legal basis</Text>
                            </StyledTH>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <StyledTH>
                                Data processing for analytics and analysis, improvement and development of the
                                Application and the Website
                            </StyledTH>
                            <StyledTH>All categories of data referred to in article 3</StyledTH>
                            <StyledTH>
                                Legitimate interest of the Controller in the improvement and development of the Site and
                                Application in the course of business activities
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>Monitoring and analysis of the use of the Application</StyledTH>
                            <StyledTH>Technical and other related information</StyledTH>
                            <StyledTH>
                                Legitimate interest of the Controller in the improvement and development of the
                                Application in the course of business activities
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>
                                Diagnosis and elimination of technical problems related to the Application and the Site
                            </StyledTH>
                            <StyledTH>Technical details</StyledTH>
                            <StyledTH>
                                Legitimate interest of the Controller in ensuring data security and prevention of
                                fraudulent activities, as well as ensuring the functionality of the Site and Application
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>Disclosure to law enforcement upon request</StyledTH>
                            <StyledTH>All categories of data referred to in article 3</StyledTH>
                            <StyledTH>Compliance with legal obligations by the Controller</StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>Providing answers to User requests</StyledTH>
                            <StyledTH>Technical and other related information</StyledTH>
                            <StyledTH>
                                In order to fulfill the obligations of the Controller to provide answers to the request
                                of the Users
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>
                                Determination of the User`s location to determine the applicable VAT rate
                            </StyledTH>
                            <StyledTH>Technical details</StyledTH>
                            <StyledTH>Execution of the License Agreement</StyledTH>
                        </tr>
                    </tbody>
                </StyledTable>
            </ScrollContainer>,
        ],
    },

    {
        title: 'Article 5. Time of collection of personal data by the Controller',
        paragraphs: ['Personal data is collected during the use of the Site and the Application by the User.'],
    },

    {
        title: 'Article 6. Transfer of personal data of the User',
        paragraphs: [
            'The Controller does not distribute the User`s personal data without his consent. It also does not transfer personal data to third parties without the consent of the User, with the exception of:',
            <StyledUnorderedList key={1}>
                <li>service providers to monitor and analyze the use of the Application;</li>
                <li>
                    the transfer of the Controller`s business through a merger, sale of company assets, financing or
                    acquisition of all or part of the Controller`s business to another legal entity;
                </li>
                <li>
                    professional advisers to the extent reasonably necessary for the purposes of risk management,
                    obtaining professional advice, or establishing, exercising or defending legal claims, whether in
                    court or administrative, or out of court.
                </li>
            </StyledUnorderedList>,
            'The Controller may engage processors and other controllers in order to fulfill its obligations to the User.',
            [
                'When paying for a subscription in accordance with the terms of the License Agreement, the User transfers his data to the Payment Provider. The processing of personal data is carried out by the Payment Provider in accordance with ',
                <StyledHref key={1} href="https://www.paddle.com/legal/privacy" target={'_blank'} rel="noreferrer">
                    https://www.paddle.com/legal/privacy
                </StyledHref>,
            ],
            'The Controller does not process any data provided by the User to the Payment Provider, does not collect or store them.',
        ],
    },

    {
        title: 'Article 7. Disclosure of personal data of Users',
        paragraphs: [
            'Personal data may be disclosed only in the following cases:',
            <StyledUnorderedList key={1}>
                <li>
                    in the event of a transfer of the Controller`s business through a merger, sale of company assets,
                    financing or acquisition of all or part of the Controller`s business to another legal entity;
                </li>
                <li>in case of receiving a corresponding request from law enforcement agencies;</li>
                <li>in other cases established by the legislation of the User`s place of residence.</li>
            </StyledUnorderedList>,
        ],
    },

    {
        title: 'Article 8. Measures taken by the Controller to ensure the security of the User`s personal data',
        paragraphs: [
            'The Controller has taken all necessary technical and organizational security measures to protect Users` personal data from accidental or unlawful destruction, loss or alteration, from unauthorized disclosure, abuse or other processing in violation of law.',
            'The Controller takes all measures to protect the data, including:',
            <StyledUnorderedList key={2}>
                <li>to protect data from accidental loss:</li>
                <li>
                    to prevent unauthorized access to data, their use, destruction or disclosure, the Controller
                    encrypts data, restricts employees` access to data.
                </li>
            </StyledUnorderedList>,
        ],
    },

    {
        title: 'Article 9. Storage of personal data of Users',
        paragraphs: [
            'The Controller stores personal data for as long as it is necessary for the purposes set out in the Privacy Policy.',
            'Cookie storage :',
            <StyledUnorderedList key={2}>
                <li>
                    a persistent cookie will be stored in the web browser and will remain valid until its set expiration
                    date, unless deleted by the User before its expiration date;
                </li>
                <li>session cookies are deleted after the User closes the browser window.</li>
            </StyledUnorderedList>,
            'The Controller stores and uses the Users` personal data to the extent necessary to fulfill the Controller`s legal obligations (including in cases established by law).',
        ],
    },

    {
        title: 'Article 10. Deleting the personal data of Users',
        paragraphs: [
            'The Controller deletes the personal data of Users after achieving the goals of processing personal data or discontinued on legal grounds for the purposes of processing.',
        ],
    },

    {
        title: 'Article 11. Rights of Users',
        paragraphs: [
            '1. The right to information and access.',
            'The User can obtain information about his personal data processed by the Controller .',
            '2. The right to data portability.',
            'The User has the right to receive his personal data from the Controller in a structured, commonly used and machine-readable format. In addition, the User may request to transfer the personal data to another controller if it is technically possible',
            '3. The right to delete.',
            'The User has the right to delete the personal data processed by the Controller if the personal data is no longer needed for the respective purposes.',
            '4. The right to object and restrict.',
            'The User may object to the processing of his personal data and restrict them in certain cases.',
            '5. The right to correction.',
            'The User has the right to correct his personal data.',
            '6. The right to withdraw consent.',
            'If the User has given consent to the processing of his personal data, he can withdraw it at any time.',
            '7. The right to appeal to the supervisory authority.',
            'The user has the right to address the complaint to the supervisory authority in the place of his residence in the following cases:',
            <StyledUnorderedList key={14}>
                <li>the User does not agree with the response to the request provided to him;</li>
                <li>the User believes that the Controller violates the law when processing personal data.</li>
            </StyledUnorderedList>,
            [
                'To exercise their rights, the User may send a request to the Controller at the following address ',
                <StyledHref key={1} href="mailto:support@bitnite.studio" target={'_blank'} rel="noreferrer">
                    support@bitnite.studio
                </StyledHref>,
            ],
            'If the User exercises the rights of third parties as an authorized representative, the Controller has the right to request all available information from him.',
        ],
    },

    {
        title: 'Article 12. Analytics',
        paragraphs: [
            'The Data Controller engages the following service providers to obtain technical and related information:',
            <ScrollContainer key={1}>
                <StyledTable>
                    <thead>
                        <tr>
                            <StyledTH width="200px">
                                <Text fontWeight="700">Service name</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Type of processed information</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Link to privacy policy</Text>
                            </StyledTH>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <StyledTH>Amplitude</StyledTH>
                            <StyledTH>
                                The User actions in the Application and the operation of the Application
                            </StyledTH>
                            <StyledTH>
                                <StyledHref
                                    key={1}
                                    href="https://amplitude.com/privacy"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    https://amplitude.com/privacy
                                </StyledHref>
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>RevenueCat</StyledTH>
                            <StyledTH>
                                Information about payments made by the User for subscriptions, subscription renewal,
                                number of payments.
                            </StyledTH>
                            <StyledTH>
                                <StyledHref
                                    key={1}
                                    href="https://www.revenuecat.com/privacy"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    https://www.revenuecat.com/privacy
                                </StyledHref>
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>Firebase</StyledTH>
                            <StyledTH>
                                The User actions in the Application and the operation of the Application
                            </StyledTH>
                            <StyledTH>
                                <StyledHref
                                    key={1}
                                    href="https://policies.google.com/privacy"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    https://policies.google.com/privacy
                                </StyledHref>
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>BugSnag</StyledTH>
                            <StyledTH>Application Errors</StyledTH>
                            <StyledTH>
                                <StyledHref
                                    key={1}
                                    href="https://docs.bugsnag.com/legal/privacy-policy/"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    https://docs.bugsnag.com/legal/privacy-policy/
                                </StyledHref>
                            </StyledTH>
                        </tr>
                        <tr>
                            <StyledTH>Doorbell</StyledTH>
                            <StyledTH>Feedback from the User regarding the operation of the Application</StyledTH>
                            <StyledTH>
                                <StyledHref
                                    key={1}
                                    href="https://doorbell.io/privacy-policy"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    https://doorbell.io/privacy-policy
                                </StyledHref>
                            </StyledTH>
                        </tr>
                    </tbody>
                </StyledTable>
            </ScrollContainer>,
        ],
    },

    {
        title: 'Article 13. Cookies',
        paragraphs: [
            <u key={0}>Purposes of Cookies</u>,
            <StyledUnorderedList key={1}>
                <li>to determine whether the User is logged into the site or not;</li>
                <li>
                    as part of the security measures used to protect the User`s accounts, including the prevention of
                    fraudulent use of login data, as well as to protect the Application and services in general;
                </li>
                <li>to analyze the use of the Application.</li>
            </StyledUnorderedList>,
            <u key={2}>Manage Cookies</u>,
            'Most browsers allow you to refuse cookies and delete cookies. The way to do this varies from browser to browser and from version to version. However, the User can obtain up-to-date information on blocking and deleting cookies at these links:',
            <ScrollContainer key={1}>
                <StyledTable>
                    <thead>
                        <tr>
                            <StyledTH width="200px">
                                <Text fontWeight="700">Name</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Type of processed information</Text>
                            </StyledTH>
                            <StyledTH>
                                <Text fontWeight="700">Link to privacy policy</Text>
                            </StyledTH>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <StyledTH>Google Analytics</StyledTH>
                            <StyledTH>
                                The number of visits to the site, the sources from which the transition to the site was
                                made
                            </StyledTH>
                            <StyledTH>
                                <StyledHref
                                    key={1}
                                    href="https://policies.google.com/privacy"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    https://policies.google.com/privacy
                                </StyledHref>
                            </StyledTH>
                        </tr>
                    </tbody>
                </StyledTable>
            </ScrollContainer>,
        ],
    },

    {
        title: 'Article 14. Rights of California Residents When Collecting Personal Information',
        paragraphs: [
            '1. The right to access the personal data.',
            'The User has the right to request information about how the Controller has collected and used his personal data in the last 12 months:',
            <StyledUnorderedList key={2}>
                <li>the categories of personal data collected;</li>
                <li>the purpose of obtaining personal data.</li>
            </StyledUnorderedList>,
            'The Controller does not store the information provided for one-time transactions (session Cookies), respectively, has the right not to provide it to the User.',
            '2. Right to delete.',
            'The Controller is obliged, at the request of the User, to delete his personal data.',
            '3. Right to Request the disclosure of collected Information',
            <StyledUnorderedList key={2}>
                <li>The categories of personal data collected by the Controller.</li>
                <li>The categories of sources from which the Controller has collected information.</li>
                <li>Purpose of collecting information.</li>
                <li>The categories of the third parties which the Controller communicates information.</li>
                <li>The categories of personal information that the Controller has disclosed for business purposes.</li>
                <li>The categories of third parties to whom the information was disclosed for commercial purposes.</li>
            </StyledUnorderedList>,
            '4. Right to object to processing',
            'The user has the right to refuse the processing of personal data in the manner prescribed by the CCPA.',
            '5. Right to non-discrimination',
            'The user has the right to exercise his rights under Article 14 of the Policy, without discrimination prohibited by the CCPA.',
            <u key={7}>The procedure for exercising the rights of the User</u>,
            [
                'The user exercises his rights by sending a request to the e-mail ',
                <StyledHref key={1} href="mailto:privacy@bitnite.studio" target={'_blank'} rel="noreferrer">
                    privacy@bitnite.studio
                </StyledHref>,
            ],
            'The Controller may ask the User for information confirming his identity in order to process his request and confirm the User`s place of residence in the state of California.',
            'The type of information processed is specified in Article 3.',
            'The purposes of information processing are specified in Article 4.',
        ],
    },

    {
        title: 'Article 15. Final provisions',
        paragraphs: [
            'The Controller does not sell the User`s personal data.',
            'The Controller does not process personal data of children under 16 years of age.',
            'The Controller may update the Privacy Policy.',
            'The Privacy Policy is in English. In the event of any discrepancy between the translated and the English version, the English version shall apply.',
            'The invalidation of any condition does not mean the invalidation of the entire Privacy Policy.',
            'The Controller notifies the User of changes to the Privacy Policy by sending notifications in the Application. The Controller strongly encourages Users to re-read the Privacy Policy from time to time.',
            'The relationship between the Controller and the User is governed by the GDPR (except as provided in Article 14).',
            [
                'The User has the right to send requests to the Controller by e-mail address: ',
                <StyledHref key={1} href="mailto:privacy@bitnite.studio" target={'_blank'} rel="noreferrer">
                    privacy@bitnite.studio
                </StyledHref>,
            ],
        ],
    },

    {
        title: 'Contact details:',
        paragraphs: [
            'Bitnite, LLP.',
            'BIN: 220840009542',
            'The Republic of Kazakhstan. Almaty city, st. Zhandosova 1/1, (Business center "Success"), 2nd floor.',
        ],
    },
];