import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';
import { WindowSizeContext } from 'context/WindowSizeContext';
import { useElementOnScreen } from 'hooks/useElementOnScreen';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useWindowSize } from 'hooks/useWindowSize';
import { ErrorPage } from 'pages/ErrorPage';
import { MainPage } from 'pages/MainPage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { RefundPrivacyPage } from 'pages/RefundPrivacyPage';
import { TermsPage } from 'pages/TermsPage';

import { Footer } from 'components/Footer';
import { Header } from 'components/Header';

import { OPTIONS } from './App.constants';
import { GlobalStyle } from './App.style';

export const App: React.FC = () => {
    useScrollToTop();
    const { isVisible, targetRef } = useElementOnScreen<HTMLAnchorElement>(OPTIONS);
    const { width, height, isMobileSize } = useWindowSize();

    useEffect(() => {
        ReactGA.initialize('G-0Y5WRS2FYZ');
    });

    return (
        <WindowSizeContext.Provider value={{ width, height, isMobileSize }}>
            <GlobalStyle>
                <Header isVisible={!isVisible}>
                    <Routes>
                        <Route index element={<MainPage ref={targetRef} />} />
                        <Route path="/refund-policy" element={<RefundPrivacyPage />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/terms" element={<TermsPage />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                    <Footer />
                </Header>
            </GlobalStyle>
        </WindowSizeContext.Provider>
    );
};