import { TextTypes } from 'components/Text/Text.types';

export const textTypeStyles = (type: TextTypes): string => {
    let fontWeightStyle: string;
    let fontSizeStyle: string;
    let fontHeightStyle: string;

    switch (type) {
        case TextTypes.TITLE16:
            fontWeightStyle = '600';
            fontSizeStyle = '16px';
            fontHeightStyle = '24px';
            break;
        case TextTypes.BODY14:
            fontWeightStyle = '400';
            fontSizeStyle = '14px';
            fontHeightStyle = '20px';
            break;
        case TextTypes.BODY16:
            fontWeightStyle = '400';
            fontSizeStyle = '16px';
            fontHeightStyle = '24px';
            break;
        default:
            fontWeightStyle = '400';
            fontSizeStyle = '16px';
            fontHeightStyle = '24px';
            break;
    }

    return `
        font-weight: ${fontWeightStyle};
        font-size: ${fontSizeStyle};
        line-height: ${fontHeightStyle};
    `;
};
