import React from 'react';
import {
    BatteryIcon,
    CalendarIcon,
    ClocksIcon,
    HDDIcon,
    MediaPlayerIcon,
    MonitoringIcon,
    StocksIcon,
    TasksIcon,
    WeatherIcon,
} from 'assets';

export const TILES = [
    {
        title: 'Weather',
        description:
            'Everything you need to know about the weather in your city - forecast for the current day and the upcoming week.',
        children: <WeatherIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Stocks',
        description: 'Get quotes of currencies and cryptocurrencies in real time - keep an eye on all the changes.',
        children: <StocksIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Tasks and Reminders',
        description: 'You won’t miss anything important - all your tasks and reminders at a glance.',
        children: <TasksIcon width={74.4} height={74.4} />,
    },
    {
        title: 'HDD/SSD',
        description: 'Control your Mac storage space.',
        children: <HDDIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Monitoring',
        description: 'CPU, RAM, RPM and other system indicators - control how they are functioning.',
        children: <MonitoringIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Music player',
        description: 'Control your playlist: this desktop widget is designed for you - no extra clicks, just music and pleasure.',
        children: <MediaPlayerIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Date & Time',
        description:
            'The exact time, date and day of the week - this is something that should always be before your eyes.',
        children: <ClocksIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Calendar',
        description: 'The process of planning becomes easier with the Calendar desktop widget for Mac.',
        children: <CalendarIcon width={74.4} height={74.4} />,
    },
    {
        title: 'Battery',
        description: 'Keep track of your battery level: feed your Mac on time.',
        children: <BatteryIcon width={74.4} height={74.4} />,
    },
];
