import { COLORS } from 'constants/colors';

import { ControlsIcon, PreviewerArrowIcon } from 'assets';
import styled from 'styled-components';

import { hexToRgba } from 'utils/hexToRgba';

export const StyledPreviewerComponent = styled.div<{ isVisible?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: ${hexToRgba(COLORS.backgroundPreviewer, 0.9)};
`;

export const StyledPreviewer = styled.div`
    position: relative;
    margin: 0px 24px;
`;

export const StyledWallpaper = styled.div<{ url?: string; activeIndex?: number }>`
    background-image: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1024px;
    max-height: calc(100vh - 240px);
    height: 576px;
    margin: 0px calc(50% - 512px);
    transition: transform 0.4s ease-out;
    transform: translateX(
        calc(-${({ activeIndex }) => (activeIndex ? activeIndex * (document.documentElement.clientWidth - 48) : 0)}px)
    );

    @media (max-width: 1192px) {
        width: 832px;
        height: 468px;
        margin: 0px calc(50% - 416px);
    }
    @media (max-width: 950px) {
        width: 656px;
        height: 369px;
        margin: 0px calc(50% - 328px);
    }
    @media (max-width: 740px) {
        width: 409px;
        height: 230px;
        margin: 0px calc(50% - 204.5px);
    }
`;

export const StyledWallpaperContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

export const StyledArrowLeftIcon = styled(PreviewerArrowIcon)`
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 550px);
    background-color: transparent;
    cursor: pointer;
    transform: rotate(180deg);
    padding: 11.5px 14.5px;

    @media (max-width: 1192px) {
        left: calc(50% - 476px);
    }
    @media (max-width: 950px) {
        left: calc(50% - 370px);
    }
    @media (max-width: 740px) {
        left: calc(50% - 240px);
    }
`;

export const StyledArrowRightIcon = styled(PreviewerArrowIcon)`
    position: absolute;
    top: calc(50% - 20px);
    right: calc(50% - 550px);
    background-color: transparent;
    cursor: pointer;
    padding: 11.5px 14.5px;

    @media (max-width: 1192px) {
        right: calc(50% - 476px);
    }
    @media (max-width: 950px) {
        right: calc(50% - 370px);
    }
    @media (max-width: 740px) {
        right: calc(50% - 240px);
    }
`;

export const StyledSwithBackgroundColor = styled.div<{ darkTheme: boolean }>`
    height: 34px;
    width: 160px;
    margin-bottom: 16px;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${({ darkTheme }) => hexToRgba(darkTheme ? COLORS.white : COLORS.black, 0.1)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const StyledSunIcon = styled.img`
    width: 18px;
    height: 18px;
`;
export const StyledSwitchButtonText = styled.div<{ darkTheme: boolean }>`
    font-family: 'SF Compact Display', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ darkTheme }) => darkTheme && COLORS.white};
`;
export const StyledSwitchButtonCoontainer = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: radial-gradient(34.08% 53.12% at 51.45% 100%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const StyledControlsIcon = styled(ControlsIcon)`
    position: absolute;
    left: calc(50% - 512px);
    top: -40px;
    cursor: pointer;

    @media (max-height: 816px) {
        left: calc((100% - ((100vh - 240px) * 16 / 9)) / 2);
    }
    @media (max-width: 1192px) {
        left: calc(50% - 418px);

        @media (max-height: 708px) {
            left: calc((100% - ((100vh - 240px) * 16 / 9)) / 2);
        }
    }
    @media (max-width: 950px) {
        left: calc(50% - 328px);

        @media (max-height: 609px) {
            left: calc((100% - ((100vh - 240px) * 16 / 9)) / 2);
        }
    }
    @media (max-width: 740px) {
        left: calc(50% - 204px);
    }
`;

export const StyledHint = styled.div<{ selfNumber: number; activeIndex: number }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ activeIndex, selfNumber }) =>
        activeIndex === selfNumber ? COLORS.white : hexToRgba(COLORS.white, 0.25)};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0px 4px;
    margin-left: ${({ selfNumber }) => selfNumber === 0 && 20}px;
    transition: transform 0.4s ease-out;
    transform: translateX(-${({ activeIndex }) => (activeIndex ? activeIndex * 16 : 0)}px);
`;

export const StyledHints = styled.div`
    white-space: nowrap;
    overflow: hidden;
    width: 48px;
    position: absolute;
    bottom: -25px;
    left: calc(50% - 24px);
`;
