import { COLORS } from 'constants/colors';

import React from 'react';
import { OopsIcon } from 'assets';
import { useWindowSizeContext } from 'context/WindowSizeContext';

import { Button } from 'components/Button';

import { StyledCircleShadow, StyledErrorPage, StyledLink, StyledText } from './ErrorPage.style';

export const ErrorPage: React.FC = () => {
    const { isMobileSize } = useWindowSizeContext();
    return (
        <StyledErrorPage>
            <OopsIcon width={isMobileSize ? 466 : 644} height={isMobileSize ? 165 : 228} />
            <StyledText fontWeight="400" fontSize="16px" lineHeight="24px">
                Something went wrong, please try again
                {!isMobileSize && <StyledCircleShadow />}
            </StyledText>
            <StyledLink to={'/'}>
                <Button
                    width={251}
                    height={48}
                    backgroundColor={COLORS.white}
                    label={'Go to home page'}
                    borderRadius={12}
                />
            </StyledLink>
        </StyledErrorPage>
    );
};
