import {
    CalendarMacWidgetIconPath,
    ClockMacWidgetIconPath,
    HDDMacWidgetIconPath,
    MonitoringMacWidgetIconPath,
    StocksMacWidgetIconPath,
    WeatherMacWidgetIconPath,
} from 'assets';

import { WidgetWrapperDiraction } from 'components/WidgetWrapper/WidgetWrapper.types';

export const WIDGETS = [
    {
        diraction: WidgetWrapperDiraction.RIGHT,
        title: 'Date and Time',
        description:
            'One of the most popular widgets among our users is the Date and Time widget. It allows you to have this important data always just before your eyes.',
        widget: ClockMacWidgetIconPath,
        top: 64,
        left: 0,
    },
    {
        diraction: WidgetWrapperDiraction.LEFT,
        title: 'HDD',
        description: 'Check and monitor your hard drive’s health. It has never been easier!',
        widget: HDDMacWidgetIconPath,
        top: 54,
        right: 0,
    },
    {
        diraction: WidgetWrapperDiraction.RIGHT,
        title: 'Monitoring',
        description: 'Control key performance indicators for your Mac, such as CPU, Fan, Ram, Battery etc',
        widget: MonitoringMacWidgetIconPath,
        top: 235,
        left: 177,
    },
    {
        diraction: WidgetWrapperDiraction.RIGHT,
        title: 'Currency and Cryptocurrency',
        description:
            'Track the dynamics of your financial instruments. Keep track of all important changes and react in time.',
        widget: StocksMacWidgetIconPath,
        top: 328,
        left: 4,
    },
    {
        diraction: WidgetWrapperDiraction.LEFT,
        title: 'Calendar',
        description:
            'Simple and convenient Calendar widget. Add a Calendar widget to control your routine.',
        widget: CalendarMacWidgetIconPath,
        top: 208,
        right: 166,
    },
    {
        diraction: WidgetWrapperDiraction.RIGHT,
        title: 'Weather',
        description:
            'No weather will take you by surprise anymore. Weather widget - all the most important weather parameters are here.',
        widget: WeatherMacWidgetIconPath,
        top: 341,
        left: 178,
    },
];

export const TITLE = 'Custom Desktop for Mac';
export const DESCRIPTION =
    'Widgetter is a direct line to upscaling your screen background into a work station perfectly tailored to your needs. Start building a custom desktop to have all the key data points and basic functions always remain available at a glance. Amazing widgets & unique design desktop themes are waiting for you inside.';
