import { COLORS } from 'constants/colors';
import { BOTTOM_TESTIMONIAL, STARS_ARRAY, TESTIMONIALS, WHITESPACE } from 'constants/testimonials';

import React from 'react';
import { StarIcon } from 'assets';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';
import { hexToRgba } from 'utils/hexToRgba';

import {
    StyledBottom,
    StyledCircleShadow,
    StyledContent,
    StyledGeoIcon,
    StyledOpinion,
    StyledQuotationMarksIcon,
    StyledQuotationMarksIconOpinion,
    StyledSignature,
    StyledTestimonial,
    StyledTestimonials,
    StyledTestimonialsColumns,
    StyledTestimonialsContainer,
    StyledTitleBottom,
} from './TestimonialsContainer.style';

export const TestimonialsContainer: React.FC = () => {
    return (
        <StyledTestimonialsContainer>
            <StyledTestimonials>
                <StyledQuotationMarksIconOpinion width={113} height={95} />
                <StyledOpinion>
                    <Text fontWeight="700" fontSize="42px" lineHeight="52px">
                        What others say about{WHITESPACE}
                    </Text>
                    <Text fontWeight="800" fontSize="42px" lineHeight="52px" color={COLORS.yellow}>
                        Widgetter
                    </Text>
                </StyledOpinion>
                {[0, 1].map((column) => (
                    <StyledTestimonialsColumns key={column}>
                        {TESTIMONIALS.slice(column ? 2 : 0, column ? 5 : 2).map((testimonial, index) => (
                            <StyledTestimonial key={index} column={column}>
                                <StyledQuotationMarksIcon width={16} height={14} />
                                <StyledContent>
                                    <Text fontWeight="700" fontSize="24px" lineHeight="32px">
                                        {testimonial.title}
                                    </Text>
                                    <Text
                                        fontWeight="300"
                                        fontSize="16px"
                                        lineHeight="32px"
                                        color={hexToRgba(COLORS.white, 0.75)}
                                    >
                                        {testimonial.description}
                                    </Text>
                                    <div>
                                        {STARS_ARRAY.slice(0, testimonial.starts).map((ind) => (
                                            <StarIcon key={ind} />
                                        ))}
                                    </div>
                                </StyledContent>
                                <StyledSignature>
                                    <Text fontWeight="500" fontSize="16px" lineHeight="28px">
                                        {testimonial.nickname}
                                    </Text>
                                    <StyledGeoIcon width={16} height={16} />
                                    <Text type={TextTypes.BODY16} color={hexToRgba(COLORS.white, 0.5)}>
                                        {testimonial.country}
                                    </Text>
                                </StyledSignature>
                            </StyledTestimonial>
                        ))}
                    </StyledTestimonialsColumns>
                ))}
            </StyledTestimonials>
            <StyledBottom>
                <StyledCircleShadow />
                <StyledTitleBottom fontWeight="700" fontSize="24px" lineHeight="24px" color={COLORS.yellow}>
                    Stay tuned
                </StyledTitleBottom>
                <Text fontWeight="400" fontSize="16px" lineHeight="24px" textAlign="center">
                    {BOTTOM_TESTIMONIAL}
                </Text>
            </StyledBottom>
        </StyledTestimonialsContainer>
    );
};
