import TheBegginingDark from './beggining/dark.jpg';
import TheBegginingLight from './beggining/light.jpg';
import EastDreamDark from './eastdream/dark.jpg';
import EastDreamLight from './eastdream/light.jpg';
import FoggyDayLight from './foggyday/light.jpg';
import GoldenAgeDark from './goldenage/dark.jpg';
import GoldenAgeLight from './goldenage/light.jpg';
import HazeDark from './haze/dark.jpg';
import HazeLight from './haze/light.jpg';
import NeonDark from './neon/dark.jpg';
import NeonLight from './neon/light.jpg';
import PolarStarDark from './polarstar/dark.jpg';
import TheBegginingSmallDark from './small/beggining/dark.png';
import EastDreamSmallDark from './small/eastdream/dark.png';
import FoggyDaySmallLight from './small/foggyday/light.png';
import GoldenAgeSmallDark from './small/goldenage/dark.png';
import HazeSmallDark from './small/haze/dark.png';
import NeonSmallDark from './small/neon/dark.png';
import PolarStarSmallDark from './small/polarstar/dark.png';
import VevetNightSmallDark from './small/vevetnight/dark.png';
import TheChoseOneDark from './thechosenone/dark.jpg';
import TheChoseOneLight from './thechosenone/light.jpg';
import VevetNightDark from './vevetnight/dark.jpg';
import VevetNightLight from './vevetnight/light.jpg';

export const THEMES = [
    [TheBegginingDark, TheBegginingLight],
    [EastDreamDark, EastDreamLight],
    [GoldenAgeDark, GoldenAgeLight],
    [HazeDark, HazeLight],
    [NeonDark, NeonLight],
    [PolarStarDark],
    [VevetNightDark, VevetNightLight],
    [FoggyDayLight],
    [TheChoseOneDark, TheChoseOneLight],
];

export const THEMES_SMALL = [
    TheBegginingSmallDark,
    EastDreamSmallDark,
    GoldenAgeSmallDark,
    HazeSmallDark,
    NeonSmallDark,
    PolarStarSmallDark,
    VevetNightSmallDark,
    FoggyDaySmallLight,
    TheChoseOneDark,
];
