import React from 'react';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';

import { TERMS } from './TermsPage.constants';
import { StyledParagraphs, StyledSubTitle, StyledTerms, StyledTermsPage, StyledTitle } from './TermsPage.style';

export const TermsPage: React.FC = () => {
    return (
        <StyledTermsPage>
            <StyledTerms>
                <StyledTitle>
                    <div>
                        <Text fontWeight="700" fontSize="32px" lineHeight="38px">
                            License Agreement
                        </Text>
                    </div>
                    <div>
                        <Text type={TextTypes.BODY16} lineHeight="1.75">
                            Version effective as of December 04, 2022
                        </Text>
                    </div>
                </StyledTitle>
                {TERMS.map((item, index) => (
                    <React.Fragment key={index}>
                        <StyledSubTitle>
                            <Text type={TextTypes.TITLE16}>{item.title}</Text>
                        </StyledSubTitle>

                        {item.paragraphs.map((paragraph, pInd) => (
                            <StyledParagraphs key={pInd} numberOfLine={pInd} countOfLines={item.paragraphs.length}>
                                <Text type={TextTypes.BODY16} lineHeight="1.75">
                                    {paragraph}
                                </Text>
                            </StyledParagraphs>
                        ))}
                    </React.Fragment>
                ))}
            </StyledTerms>
        </StyledTermsPage>
    );
};
