import { COLORS } from 'constants/colors';

import { Link } from 'react-router-dom';
import { textTypeStyles } from 'mixins/TextTypeStyles';
import styled from 'styled-components';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledFooterComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 207px;
    width: 100%;
    background-color: ${COLORS.backgroundFooter};

    @media (max-width: 1000px) {
        height: auto;
    }
`;

export const StyledInternalBox = styled.div`
    display: flex;
    max-width: 1110px;
    width: 100%;
    margin: 0px 165px;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1000px) {
        margin: 24px;
        margin-bottom: 0;
    }
`;

export const StyledTopInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 330px);
    max-width: 1110px;
    padding-bottom: 40px;
    border-bottom: 1px solid;
    border-color: ${hexToRgba('#707070', 0.2)};

    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: calc(100% - 48px);
        margin-top: 24px;
        padding-bottom: 0;
    }
`;

export const StyledLogo = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTextBox = styled.div`
    width: 396px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
        width: 393px;
        margin-bottom: 32px;
    }

    @media (max-width: 460px) {
        width: 100%;
        flex-wrap: wrap;
        column-gap: 12px;
        margin-bottom: 32px;
    }
`;

export const StyledText = styled(Text)`
    @media (max-width: 1000px) {
        font-size: 14px;
    }
`;

export const StyledLogoAndContact = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 16px;
    }
`;

export const StyledBottomInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 330px);
    margin: 25px 0px 24px 0px;
    max-width: 1110px;

    @media (max-width: 1000px) {
        width: calc(100% - 48px);
        margin-top: 16px;
    }
`;

export const StyledWillow = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTextRights = styled(Text)`
    margin-left: 16px;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    font-family: 'Rubik', sans-serif;
    ${textTypeStyles(TextTypes.TITLE16)};
    color: ${COLORS.white};

    @media (max-width: 1000px) {
        font-size: 14px;
    }
`;

//Может нужно будет вернуть
// export const StyledSocialNet = styled.div`
//     width: 80px;
//     height: 32px;
//     display: none;
//     justify-content: space-between;

//     @media (max-width: 1000px) {
//         display: flex;
//     }
// `;

// export const StyledBottomSocialNet = styled.div`
//     width: 80px;
//     height: 32px;
//     display: flex;
//     justify-content: space-between;

//     @media (max-width: 1000px) {
//         display: none;
//     }
// `;