import { COLORS } from 'constants/colors';

import { textTypeStyles } from 'mixins/TextTypeStyles';
import styled from 'styled-components';

import { TextProps, TextTypes } from './Text.types';

export const Text = styled.span<TextProps>`
    white-space: pre-wrap;
    color: ${({ color }) => color || COLORS.white};
    margin: ${({ margin }) => margin};
    text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
    font-family: 'Rubik', sans-serif;
    ${({ type }) => textTypeStyles(type || TextTypes.BODY16)};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    line-height: ${({ lineHeight }) => lineHeight};
    text-align: ${({ textAlign }) => textAlign || 'left'};
    font-style: normal;
    -webkit-text-size-adjust: 100%;
`;
