import { COLORS } from 'constants/colors';

//import { INST_HREF, TWITTER_HREF } from 'constants/footer';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { LogoIcon, WillowIcon } from 'assets';
import { useWindowSizeContext } from 'context/WindowSizeContext';

import { TextTypes } from 'components/Text/Text.types';

import {
    StyledBottomInfo,
    //StyledBottomSocialNet,
    StyledFooterComponent,
    StyledLink,
    StyledLogo,
    StyledLogoAndContact,
    //StyledSocialNet,
    StyledText,
    StyledTextBox,
    StyledTextRights,
    StyledTopInfo,
    StyledWillow,
} from './Footer.style';

export const Footer: React.FC = () => {
    const { isMobileSize } = useWindowSizeContext();
    const location = useLocation();
    const scrollUp = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {!(!['/', '/privacy', '/terms', '/refund-policy'].includes(location.pathname) && isMobileSize) && (
                <StyledFooterComponent>
                    <StyledTopInfo>
                        <StyledLogoAndContact>
                            <StyledLogo>
                                <StyledLink to="/" onClick={scrollUp}>
                                    <LogoIcon fill={COLORS.yellow} width={171} height={38} />
                                </StyledLink>
                            </StyledLogo>
                            {/* 
                            *Может нужно будет вернуть

                            <StyledSocialNet>
                                <a href={INST_HREF} target={'_blank'} rel="noreferrer">
                                    <InstagramIcon />
                                </a>
                                <a href={TWITTER_HREF} target={'_blank'} rel="noreferrer">
                                    <TwitterIcon />
                                </a>
                            </StyledSocialNet> */}
                        </StyledLogoAndContact>
                        <StyledTextBox>
                            <StyledLink to="/terms">
                                <StyledText type={TextTypes.TITLE16}>Terms of service</StyledText>
                            </StyledLink>
                            <StyledLink to="/privacy">
                                Privacy Policy
                            </StyledLink>
                            <StyledLink to="/refund-policy">
                                Refund Policy
                            </StyledLink>
                        </StyledTextBox>
                    </StyledTopInfo>
                    <StyledBottomInfo>
                        <StyledWillow>
                            <a href="https://bitnite.studio/" target={'_blank'} rel="noreferrer">
                                <WillowIcon min-width={92} />
                            </a>

                            <StyledTextRights fontWeight="400" fontSize="14px" lineHeight="20px">
                                © 2022-2024 Bitnite, LLP. All rights reserved
                            </StyledTextRights>
                        </StyledWillow>
                        {/*
                            *Может нужно будет вернуть
                        <StyledBottomSocialNet>
                            <a href={INST_HREF} target={'_blank'} rel="noreferrer">
                                <InstagramIcon />
                            </a>
                            <a href={TWITTER_HREF} target={'_blank'} rel="noreferrer">
                                <TwitterIcon />
                            </a>
                        </StyledBottomSocialNet> */}
                    </StyledBottomInfo>
                </StyledFooterComponent>
            )}
        </>
    );
};
