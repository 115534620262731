export const COLORS = {
    blue: '#0092E4',
    blueHover: '#00A3FF',
    green: '#00E477',
    greenHover: '#02EA84',
    white: '#FFFFFF',
    black: '#000000',
    yellow: '#FFB800',
    backgroundHeader: '#0D0E0E',
    background: '#111213',
    backgroundFooter: '#060709',
    backgroundPreviewer: '#0D0E0E',
};
