import { COLORS } from 'constants/colors';

import React, { useCallback, useEffect, useState } from 'react';
import { MoonIcon, SunIconPath } from 'assets';
import { THEMES } from 'assets/themes/index';
import { useArrowHandler } from 'hooks/useArrowHandler';

import { hexToRgba } from 'utils/hexToRgba';
import { stopPropagation } from 'utils/stopPropagation';

import { useEscHandler } from './Previewer.hooks';
import {
    StyledArrowLeftIcon,
    StyledArrowRightIcon,
    StyledControlsIcon,
    StyledHint,
    StyledHints,
    StyledPreviewer,
    StyledPreviewerComponent,
    StyledSunIcon,
    StyledSwitchButtonCoontainer,
    StyledSwitchButtonText,
    StyledSwithBackgroundColor,
    StyledWallpaper,
    StyledWallpaperContainer,
} from './Previewer.style';
import { PreviewerProps } from './Previewer.types';

export const Previewer: React.FC<PreviewerProps> = ({ setVisiblePreviewer, activeIndex, visiblePreviewer }) => {
    const [darkBackground, setDarkBackground] = useState(true);
    const [currentTheme, setCurrentTheme] = useState(activeIndex);

    useEffect(() => {
        if (!visiblePreviewer) {
            setDarkBackground(true);
        }
        setCurrentTheme(activeIndex);
    }, [activeIndex, visiblePreviewer]);
    const addToIndex = useCallback((e: React.MouseEvent<SVGSVGElement>) => {
        setCurrentTheme((prev) => prev + 1);
        e.stopPropagation();
    }, []);
    const subToIndex = useCallback((e: React.MouseEvent<SVGSVGElement>) => {
        setCurrentTheme((prev) => prev - 1);
        e.stopPropagation();
    }, []);
    const switchThemeColor = useCallback(() => setDarkBackground((prev) => !prev), []);
    const setUnvisiblePreviewer = useCallback(() => {
        setVisiblePreviewer(false);
    }, [setVisiblePreviewer]);

    useEscHandler(setVisiblePreviewer);
    useArrowHandler(setCurrentTheme, THEMES.length, visiblePreviewer);

    return (
        <StyledPreviewerComponent isVisible={visiblePreviewer} onClick={setUnvisiblePreviewer}>
            <StyledPreviewer>
                <StyledWallpaperContainer>
                    {THEMES?.map((item, ind) => (
                        <StyledWallpaper
                            key={ind}
                            url={darkBackground ? item[0] : item[item.length - 1]}
                            activeIndex={currentTheme}
                            onClick={stopPropagation}
                        >
                            {THEMES[currentTheme].length === 2 && (
                                <StyledSwitchButtonCoontainer>
                                    <StyledSwithBackgroundColor onClick={switchThemeColor} darkTheme={darkBackground}>
                                        {darkBackground ? (
                                            <>
                                                <StyledSunIcon src={SunIconPath} />
                                                <StyledSwitchButtonText darkTheme={darkBackground}>
                                                    Turn on Light Mode
                                                </StyledSwitchButtonText>
                                            </>
                                        ) : (
                                            <>
                                                <MoonIcon fill={hexToRgba(COLORS.black, 0.9)} />
                                                <StyledSwitchButtonText darkTheme={darkBackground}>
                                                    Turn on Dark Mode
                                                </StyledSwitchButtonText>
                                            </>
                                        )}
                                    </StyledSwithBackgroundColor>
                                </StyledSwitchButtonCoontainer>
                            )}
                        </StyledWallpaper>
                    ))}
                </StyledWallpaperContainer>
                {currentTheme !== 0 && <StyledArrowLeftIcon onClick={subToIndex} />}
                {currentTheme !== THEMES.length - 1 && <StyledArrowRightIcon onClick={addToIndex} />}
                <StyledControlsIcon width={32} height={32} />
                <StyledHints onClick={stopPropagation}>
                    {THEMES?.map((_, ind) => (
                        <StyledHint key={ind} selfNumber={ind} activeIndex={currentTheme} />
                    ))}
                </StyledHints>
            </StyledPreviewer>
        </StyledPreviewerComponent>
    );
};
