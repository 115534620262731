import { COLORS } from 'constants/colors';
import { DESCRIPTION } from 'constants/slider';

import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import { THEMES } from 'assets/themes/index';
import { useWindowSizeContext } from 'context/WindowSizeContext';
import { useArrowHandler } from 'hooks/useArrowHandler';

import { Text } from 'components/Text';
import { TextTypes } from 'components/Text/Text.types';
import { hexToRgba } from 'utils/hexToRgba';

import { useCloserHandler, useSwipeEnd } from './Slider.hooks';
import {
    StyledArrowIcon,
    StyledArrowLeftIcon,
    StyledArrowRightIcon,
    StyledDescriptionText,
    StyledGradient,
    StyledSlider,
    StyledSliderContainer,
    StyledTest,
    StyledText,
    StyledWallpaperContainer,
    StyledWallpaperImg,
    StyledWallpapers,
} from './Slider.style';
import { SliderProps } from './Slider.types';

export const Slider: React.FC<SliderProps> = ({
    setVisiblePreviewer,
    activeIndex,
    setActiveIndex,
    visiblePreviewer,
    setMouseSlide,
}) => {
    const wallpapersContainerRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);

    const { width } = useWindowSizeContext();
    const [action, setAction] = useState(false);
    const [update, setUpdate] = useState(true);
    const [inProgress, setInProgress] = useState(false);
    const [currentPos, setCurrentPos] = useState(0);
    const [drag, setDrag] = useState(false);

    const addToIndex = useCallback(() => {
        setActiveIndex((prev) => (prev + 1) % THEMES.length);
        setUpdate((prev) => !prev);
        setAction(true);
    }, [setActiveIndex]);
    const subToIndex = useCallback(() => {
        setActiveIndex((prev) => prev - 1);
        setUpdate((prev) => !prev);
        setAction(true);
    }, [setActiveIndex]);
    const swipeAction = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (drag) {
                const container = sliderRef.current as Element;
                container.scrollBy(currentPos - event.clientX, 0);
                setCurrentPos(event.clientX);
                setMouseSlide(true);
            }
            event.preventDefault();
        },
        [currentPos, drag, setMouseSlide],
    );
    const swipeStart = useCallback((event: MouseEvent<HTMLDivElement>) => {
        setInProgress(true);
        setDrag(true);
        setCurrentPos(event.clientX);
        event.preventDefault();
    }, []);

    useCloserHandler(
        setActiveIndex,
        wallpapersContainerRef,
        sliderRef,
        width,
        THEMES.length,
        activeIndex,
        setInProgress,
    );
    useArrowHandler(setActiveIndex, THEMES.length, !visiblePreviewer);
    useSwipeEnd(setInProgress, setDrag);

    useEffect(() => {
        const interval =
            action && !visiblePreviewer && !inProgress
                ? setTimeout(() => {
                      setAction(false);
                  }, 3000)
                : setInterval(() => {
                      setActiveIndex((prev) => (prev + 1) % THEMES.length);
                  }, 3000);
        if (visiblePreviewer || inProgress) clearInterval(interval);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [action, inProgress, setActiveIndex, update, visiblePreviewer]);
    useEffect(() => {
        const currentContainer = wallpapersContainerRef?.current as Element;
        const currentSlider = sliderRef?.current as Element;
        const begginOfWallpaperContainer = currentContainer.getBoundingClientRect().x;
        const elementX =
            -Math.max((width - 1385) / 2, 0) -
            Math.max(width / 2 - 578 - Math.max((width - 1385) / 2, 0), 0) -
            (isDesktop ? 24 : 0) +
            (activeIndex === 0
                ? 0
                : (width > 1158 ? 427 : 1030 - width / 2 - 24) + (activeIndex === 1 ? 0 : (activeIndex - 1) * 670));

        currentSlider.scrollBy({ top: 0, left: elementX + begginOfWallpaperContainer, behavior: 'smooth' });
    }, [activeIndex, width]);

    return (
        <StyledSliderContainer>
            <StyledText>
                <Text fontWeight="600" fontSize="24px" lineHeight="24px">
                    Well-designed Desktop Themes
                </Text>
                <StyledDescriptionText type={TextTypes.BODY14} color={hexToRgba(COLORS.white, 0.75)}>
                    {DESCRIPTION}
                </StyledDescriptionText>
            </StyledText>
            <StyledSlider>
                <StyledWallpapers>
                    <StyledTest
                        onScroll={() => setInProgress(true)}
                        ref={sliderRef}
                        onMouseDown={swipeStart}
                        onMouseMove={swipeAction}
                    >
                        <StyledWallpaperContainer ref={wallpapersContainerRef}>
                            {THEMES?.map((item, ind) => (
                                <StyledWallpaperImg
                                    src={item[0]}
                                    key={ind}
                                    count={THEMES.length}
                                    positionNumber={ind}
                                    activeIndex={activeIndex}
                                    width={width}
                                    onClick={!isMobileOnly ? () => setVisiblePreviewer(ind) : undefined}
                                />
                            ))}
                        </StyledWallpaperContainer>
                    </StyledTest>
                    {!isMobileOnly && (
                        <>
                            {activeIndex !== 0 && (
                                <StyledGradient
                                    positionRight={false}
                                    onClick={() => setVisiblePreviewer(Math.max(0, activeIndex - 1))}
                                />
                            )}
                            {activeIndex !== THEMES.length - 1 && (
                                <StyledGradient
                                    positionRight={true}
                                    onClick={() => setVisiblePreviewer(activeIndex + 1)}
                                />
                            )}
                            {activeIndex !== 0 && (
                                <StyledArrowLeftIcon onClick={subToIndex}>
                                    <StyledArrowIcon width={40} height={40} />
                                </StyledArrowLeftIcon>
                            )}
                            <StyledArrowRightIcon onClick={addToIndex}>
                                <StyledArrowIcon width={40} height={40} />
                            </StyledArrowRightIcon>
                        </>
                    )}
                </StyledWallpapers>
            </StyledSlider>
        </StyledSliderContainer>
    );
};
