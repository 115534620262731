import { useCallback, useEffect } from 'react';

export const useEscHandler = (visiblePreviewer: React.Dispatch<React.SetStateAction<boolean>>) => {
    const changeFuncToFalse = useCallback(
        (event: KeyboardEvent) => {
            const { key } = event;
            if (key === 'Escape') {
                visiblePreviewer(false);
            }
        },
        [visiblePreviewer],
    );

    useEffect(() => {
        window.addEventListener('keydown', changeFuncToFalse);

        return () => {
            window.removeEventListener('keydown', changeFuncToFalse);
        };
    }, [changeFuncToFalse]);
};
