import { Link } from 'react-router-dom';
import { BackgroundIcon } from 'assets';
import styled from 'styled-components';

import { Text } from 'components/Text';
import { hexToRgba } from 'utils/hexToRgba';

export const StyledErrorPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${BackgroundIcon});
    min-height: 744px;
    height: calc(100vh - 72px - 208px);
    overflow: hidden;

    @media (max-width: 1000px) {
        min-height: 952px;
        height: calc(100vh - 72px);
        background-image: none;
    }
`;

export const StyledText = styled(Text)`
    margin-top: 88px;
    margin-bottom: 24px;
    position: relative;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    z-index: 1;
`;

export const StyledCircleShadow = styled.div`
    z-index: 0;
    position: absolute;
    top: -249.5px;
    left: calc((100% - 499px) / 2);
    width: 499px;
    height: 499px;
    background: ${hexToRgba('#7AC6F0', 0.2)};
    opacity: 0.5;
    filter: blur(360px);
`;
