import styled from 'styled-components';

import { hexToRgba } from 'utils/hexToRgba';

import { ButtonProps } from './Button.types';

export const StyledButtonComponent = styled.a<ButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: ${({ borderRadius }) => borderRadius || 12}px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    box-shadow: ${({ backgroundColor }) => `0px 8px 52px 0px ${hexToRgba(backgroundColor, 0.25)}`};

    &:hover {
        background-color: ${({ backgroundColorHover }) => backgroundColorHover};
        box-shadow: ${({ backgroundColor }) => `0px 8px 52px 0px ${hexToRgba(backgroundColor, 0.75)}`};
    }
`;
