import { COLORS } from 'constants/colors';

import styled from 'styled-components';

import { Text } from 'components/Text';

export const StyledPrivacyPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 72px - 208px);
    overflow: hidden;
    width: 100%;
`;

export const StyledPrivacy = styled.div`
    margin: 124px 165px 121px 165px;
    width: calc(100% - 330px);

    @media (max-width: 1000px) {
        margin: 56px 24px;
        width: calc(100% - 48px);
    }
`;

export const StyledTitle = styled.div`
    padding-bottom: 12px;
`;

export const StyledSubTitle = styled.div`
    padding-top: 12px;
`;

export const StyledParagraphs = styled.div<{ numberOfLine: number; countOfLines: number }>`
    ${({ numberOfLine, countOfLines }) => numberOfLine === countOfLines - 1 && 'padding-bottom:12px'};
    ${({ numberOfLine }) => numberOfLine === 0 && 'padding-top:12px'};
`;

export const StyledHref = styled.a`
    text-decoration: none;
    color: rgb(86, 86, 255);
`;

export const StyledContactMobile = styled(Text)`
    color: ${COLORS.yellow};

    @media (min-width: 1001px) {
        display: none;
    }
`;

export const StyledUnorderedList = styled.ul`
    padding: 0px;
    padding-left: 20px;
    margin: 0;
`;